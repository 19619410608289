import React from 'react';
import { Box, Typography, TextField, Button, Grid, IconButton } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const GalleryLocalesForm = ({ formData, errors, handleGalleryUpload, handleChange, handleNext, handleBack, handleRemoveImage }) => {
  const remainingPhotos = 4 - (formData.galleryPreviews?.length || 0);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Galería y Locales
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          component="label"
          fullWidth
          disabled={remainingPhotos === 0}
          startIcon={<CollectionsIcon />}
          sx={{
            mb: 1,
            backgroundColor: "#115beb",
            '&.Mui-disabled': {
              backgroundColor: '#666',
              color: '#999'
            }
          }}
        >
          {remainingPhotos > 0 
            ? `Agregar fotos (${remainingPhotos} restantes)`
            : "Límite de fotos alcanzado"}
          <input
            type="file"
            hidden
            accept="image/*"
            multiple
            onChange={handleGalleryUpload}
          />
        </Button>
        {errors.gallery && (
          <Typography color="error" variant="caption" sx={{ display: 'block', mt: 1 }}>
            {errors.gallery}
          </Typography>
        )}
      </Box>

      {formData.galleryPreviews?.length > 0 && (
        <Grid container spacing={2} sx={{ mb: 3 }}>
          {formData.galleryPreviews.map((preview, index) => (
            <Grid item xs={6} sm={6} md={3} key={index}>
              <Box sx={{ 
                position: 'relative',
                '&:hover .delete-button': {
                  opacity: 1
                }
              }}>
                <img
                  src={preview.url}
                  alt={`Gallery item ${index + 1}`}
                  style={{ 
                    width: '100%',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '8px'
                  }}
                />
                <IconButton
                  className="delete-button"
                  onClick={() => handleRemoveImage(index)}
                  sx={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: '#fff',
                    opacity: 0,
                    transition: 'opacity 0.2s',
                    '&:hover': {
                      backgroundColor: 'rgba(255,0,0,0.7)'
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
          {remainingPhotos > 0 && (
            <Grid item xs={6} sm={6} md={3}>
              <Button
                component="label"
                sx={{
                  width: '100%',
                  height: '150px',
                  border: '2px dashed #666',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    border: '2px dashed #999'
                  }
                }}
              >
                <AddPhotoAlternateIcon sx={{ fontSize: 40, color: '#666', mb: 1 }} />
                <Typography variant="body2" color="#666">
                  Agregar foto
                </Typography>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleGalleryUpload}
                />
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      <TextField
        fullWidth
        multiline
        rows={3}
        label="Locales donde sueles tocar"
        name="locales"
        value={formData.locales}
        onChange={handleChange}
        error={!!errors.locales}
        helperText={errors.locales || "Separa los nombres de los locales con comas"}
        sx={{ mb: 2 }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          sx={{ color: '#ffffff', borderColor: '#ffffff' }}
        >
          Atrás
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ backgroundColor: "#4caf50" }}
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};

export default GalleryLocalesForm; 