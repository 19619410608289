import React from 'react';
import {
  Typography,
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';

const cardStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  p: 3,
};

const SongList = ({ 
  tableData, 
  page, 
  rowsPerPage, 
  handleChangePage, 
  handleChangeRowsPerPage 
}) => {
  return (
    <Card sx={cardStyles}>
      <Typography variant="h5" sx={{ mb: 3, color: '#4ECDC4' }}>
        Listado Completo de Solicitudes
      </Typography>
      <TableContainer component={Paper} sx={{ 
        backgroundColor: 'transparent',
        maxHeight: 600 
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                backgroundColor: '#1e1e1e', 
                color: '#fff',
                fontWeight: 'bold'
              }}>Artista</TableCell>
              <TableCell sx={{ 
                backgroundColor: '#1e1e1e', 
                color: '#fff',
                fontWeight: 'bold'
              }}>Canción</TableCell>
              <TableCell sx={{ 
                backgroundColor: '#1e1e1e', 
                color: '#fff',
                fontWeight: 'bold'
              }}>Género</TableCell>
              <TableCell sx={{ 
                backgroundColor: '#1e1e1e', 
                color: '#fff',
                fontWeight: 'bold'
              }}>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index} sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'rgba(255, 255, 255, 0.02)',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  }
                }}>
                  <TableCell sx={{ color: '#fff' }}>{row.artist}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{row.song}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{row.genre}</TableCell>
                  <TableCell>
                    <Box sx={{ 
                      color: row.rawOption === 'se puso' ? '#4ECDC4' : 
                             row.rawOption === 'No la quiero poner' ? '#FF6B6B' : 
                             '#FFC107',
                      fontWeight: 'medium'
                    }}>
                      {row.option}
                    </Box>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={tableData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ 
          color: '#fff',
          '.MuiTablePagination-selectIcon': { color: '#fff' },
          '.MuiTablePagination-select': { color: '#fff' }
        }}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </Card>
  );
};

export default SongList; 