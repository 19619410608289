import React from 'react';
import { Box, Typography, Button, Alert } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import YAPE from '../../assets/yape-plin.webp';

const PaymentMethods = ({ copyMessage, copyToClipboard }) => (
  <>
    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
      Elige tu método de pago:
    </Typography>
    {copyMessage && (
      <Alert
        severity="info"
        sx={{
          mb: 2,
          textAlign: 'center',
          backgroundColor: '#e3f2fd',
          color: '#0d47a1',
        }}
      >
        {copyMessage}
      </Alert>
    )}
    <Box
      sx={{
        textAlign: 'left',
        mb: 3,
        p: 2,
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: 'all 0.3s',
        '&:hover': { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' },
      }}
    >
      <Box display="flex" alignItems="center">
        <Box component="img" src={YAPE} alt="Logo de Yape" sx={{ width: 70, height: 45, mr: 2, borderRadius: '4px' }} />
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#555' }}>
            Pago por Yape o Plin
          </Typography>
          <Typography variant="body2" sx={{ color: '#555' }}>
            Número: <strong>945429543</strong>
          </Typography>
        </Box>
      </Box>
      <Button onClick={() => copyToClipboard('945429543')} sx={{ minWidth: 'auto', p: 0 }}>
        <ContentCopy fontSize="small" />
      </Button>
    </Box>

    <Box
      sx={{
        textAlign: 'left',
        mb: 3,
        p: 2,
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: 'all 0.3s',
        '&:hover': { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' },
      }}
    >
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#555' }}>
          Transferencia Bancaria
        </Typography>
        <Typography variant="body2" sx={{ color: '#555' }}>
          Nº de cuenta Soles: <strong>47597235559093</strong>
          <Button onClick={() => copyToClipboard('47597235559093')} sx={{ minWidth: 'auto', paddingRight: '2px' }}>
            <ContentCopy fontSize="small" />
          </Button>
        </Typography>
        <Typography variant="body2" sx={{ color: '#555' }}>
          Nº de cuenta interbancaria: <strong>00247519723555909325</strong>
          <Button onClick={() => copyToClipboard('00247519723555909325')} sx={{ minWidth: 'auto', paddingRight: '2px' }}>
            <ContentCopy fontSize="small" />
          </Button>
        </Typography>
        <Typography variant="body2" sx={{ color: '#555' }}>
          Banco: <strong>Banco de Crédito del Perú (BCP)</strong>
        </Typography>
        <Typography variant="body2" sx={{ color: '#555' }}>
          Titular: <strong>ROBERTO IGNACIO SALAZAR GARCIA</strong>
        </Typography>
      </Box>
    </Box>
  </>
);

export default PaymentMethods; 