import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DJView from './view/DJView';
import SongRequestForm from './view/SongRequestForm';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; 
import Home from './view/home';
import AdminView from './view/AdminView';
import AboutUs from './view/AboutUs'; 
import KaraokeSongRequestForm from './view/KaraokeSongRequestForm';
import KaraokeView from './view/KaraokeView';
import DjProfile from './view/DjProfile';
import Pricing from './view/Pricing';
import QRProduct from './view/QRDJProduct';
import ProfileDJProduct from './view/ProfileDJProduct';
import DataAnalyticsView from './view/DataAnalyticsView';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nosotros" element={<AboutUs />} /> 
          <Route path='/comprar'element={<Pricing/>} />
          <Route path="/djv/:djId" element={<DJView />} />
          <Route path="/rs/:formId" element={<SongRequestForm />} />
          <Route path="/karaoke/:formId" element={<KaraokeSongRequestForm />} />
          <Route path="/karaokeview/:karaokeId" element={<KaraokeView />} />
          <Route path="/adv/:id" element={<AdminView />} />
          <Route path="/dj/:djName" element={<DjProfile />} />
          <Route path="/qr-dj-product" element={<QRProduct />} />
          <Route path="/profile-dj-product" element={<ProfileDJProduct />} />
          <Route path="/analytics/:djId" element={<DataAnalyticsView />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
