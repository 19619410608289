import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

const KaraokePlaceInfoSection = ({ placeInfo, loadingPlaceInfo }) => (
  <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
    {loadingPlaceInfo ? (
      <CircularProgress />
    ) : (
      <>
        {placeInfo.Logo && placeInfo.Logo.length > 0 && (
          <img
            src={placeInfo.Logo[0].url}
            alt={placeInfo.Name}
            style={{ width: '40%', height: '40%', borderRadius: 10 }}
          />
        )}
        {placeInfo.Foto && placeInfo.Foto.length > 0 ? (
          <img
            src={placeInfo.Foto[0].url}
            alt={placeInfo.Name}
            style={{ width: '90%', height: '90%', borderRadius: 10 }}
          />
        ) : (
          <Typography variant="h6" color="error">
            No se pudo cargar la imagen del lugar
          </Typography>
        )}
        <Typography
          variant="h5"
          component="h1"
          sx={{ marginTop: 2 }}
        >
          {placeInfo.Name
            ? `Tocando ${placeInfo.Name}`
            : 'Bienvenido al karaoke'}
        </Typography>
      </>
    )}
  </Box>
);

export default KaraokePlaceInfoSection;
