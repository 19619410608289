import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const cardStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  p: 3,
  height: '100%'
};

const PlaybackStatus = ({ playOptionsData, statistics }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card sx={{ ...cardStyles, height: 400 }}>
          <Typography variant="h5" sx={{ mb: 3, color: '#4ECDC4', textAlign: 'center' }}>
            Estado de Reproducción
          </Typography>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={playOptionsData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={120}
                label={({name, percent}) => `${name} (${(percent * 100).toFixed(1)}%)`}
              >
                {playOptionsData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={entry.rawOption === 'Se puso' ? '#4ECDC4' : 
                          entry.rawOption === 'No la quiero poner' ? '#FF6B6B' : 
                          '#FFC107'} 
                  />
                ))}
              </Pie>
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: '#1e1e1e', 
                  border: 'none',
                  borderRadius: '8px',
                  padding: '10px'
                }}
                labelStyle={{ color: '#fff' }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card sx={{ ...cardStyles }}>
          <Typography variant="h5" sx={{ mb: 3, color: '#4ECDC4', textAlign: 'center' }}>
            Resumen de Reproducción
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h3" sx={{ color: '#4ECDC4' }}>
                {statistics.playedPercentage}
              </Typography>
              <Typography variant="body1" sx={{ color: '#fff' }}>
                Tasa de Reproducción
              </Typography>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-around', 
              mt: 2 
            }}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" sx={{ color: '#4ECDC4' }}>
                  {statistics.playedSongs}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Canciones Reproducidas
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" sx={{ color: '#FF6B6B' }}>
                  {statistics.totalRequests - statistics.playedSongs}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Canciones No Reproducidas
                </Typography>
              </Box>
            </Box>

            {/* Estadísticas adicionales */}
            <Box sx={{ 
              mt: 4,
              display: 'grid',
              gap: 2,
              backgroundColor: 'rgba(255,255,255,0.05)',
              p: 2,
              borderRadius: '8px'
            }}>
              <Typography variant="h6" sx={{ color: '#4ECDC4', textAlign: 'center' }}>
                Detalles Adicionales
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: '#fff' }}>
                    Total de Solicitudes:
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#4ECDC4' }}>
                    {statistics.totalRequests}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: '#fff' }}>
                    Promedio Diario:
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#4ECDC4' }}>
                    {statistics.songsPerDay}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PlaybackStatus; 