import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
  useTheme,
  useMediaQuery,
  LinearProgress,
  IconButton,
  Collapse,
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const cardStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  p: 3,
  height: '100%'
};

const COLORS = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD',
  '#D4A5A5'
];

const GenreAnalysis = ({ genreAnalysis }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedGenre, setExpandedGenre] = React.useState(null);

  const pieChartData = genreAnalysis.map(genre => ({
    name: genre.name,
    value: genre.total,
    playRate: genre.playRate,
    artistCount: genre.artistCount,
    songCount: genre.songCount
  }));

  const MobileGenreCard = ({ genre, index }) => {
    const isExpanded = expandedGenre === index;
    
    return (
      <Box 
        sx={{ 
          mb: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '12px',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          onClick={() => setExpandedGenre(isExpanded ? null : index)}
        >
          <Box>
            <Typography 
              variant="h6" 
              sx={{ 
                color: COLORS[index % COLORS.length],
                fontSize: '1rem',
                fontWeight: 'bold'
              }}
            >
              {genre.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              {genre.total} solicitudes
            </Typography>
          </Box>
          <IconButton 
            size="small" 
            sx={{ color: '#fff' }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>

        <Collapse in={isExpanded}>
          <Box sx={{ p: 2, pt: 0 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ color: '#fff', mb: 0.5 }}>
                Tasa de reproducción: {genre.playRate}%
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={parseFloat(genre.playRate)}
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#4ECDC4'
                  },
                  borderRadius: 1,
                  height: 6
                }}
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Reproducidas: {genre.played}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  No reproducidas: {genre.notPlayed}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Artistas: {genre.artistCount}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Canciones: {genre.songCount}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
    );
  };

  const PieChartComponent = ({ height = 350 }) => (
    <Box sx={{ 
      height: height,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <ResponsiveContainer height={height - 100}>
        <PieChart>
          <Pie
            data={pieChartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={isMobile ? 40 : 60}
            outerRadius={isMobile ? 80 : 120}
            paddingAngle={2}
          >
            {pieChartData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={COLORS[index % COLORS.length]}
                strokeWidth={1}
              />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: 'rgba(30, 30, 30, 0.9)',
              border: 'none',
              borderRadius: '8px',
              padding: '8px 12px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.2)'
            }}
            formatter={(value, name, props) => [
              <Box sx={{ fontSize: '0.9rem' }}>
                <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>
                  {value} solicitudes ({((value/pieChartData.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(1)}%)
                </Typography>
              </Box>
            ]}
            labelStyle={{ color: '#fff', fontWeight: 'bold', fontSize: '0.9rem' }}
          />
        </PieChart>
      </ResponsiveContainer>
      
      <Box 
        sx={{ 
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 1,
          px: 1,
          mt: 1,
          maxHeight: isMobile ? '80px' : '100px',
          overflowY: 'auto'
        }}
      >
        {pieChartData.map((entry, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '4px',
              padding: '4px 8px',
              fontSize: '0.75rem'
            }}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: COLORS[index % COLORS.length],
                mr: 1
              }}
            />
            <Typography 
              variant="caption" 
              sx={{ 
                color: '#fff',
                whiteSpace: 'nowrap'
              }}
            >
              {entry.name} ({((entry.value/pieChartData.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(1)}%)
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{
            ...cardStyles,
            minHeight: '450px'
          }}>
            <Typography variant="h5" sx={{ 
              mb: 3, 
              color: '#4ECDC4', 
              textAlign: 'center',
              fontSize: '1.2rem'
            }}>
              Distribución de Géneros
            </Typography>
            <PieChartComponent height={380} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={cardStyles}>
            <Typography variant="h5" sx={{ 
              mb: 3, 
              color: '#4ECDC4', 
              textAlign: 'center',
              fontSize: '1.2rem'
            }}>
              Detalles por Género
            </Typography>
            {genreAnalysis.map((genre, index) => (
              <MobileGenreCard 
                key={index} 
                genre={genre} 
                index={index}
              />
            ))}
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card sx={{
          ...cardStyles,
          minHeight: '550px'
        }}>
          <Typography variant="h5" sx={{ mb: 3, color: '#4ECDC4', textAlign: 'center' }}>
            Distribución de Géneros
          </Typography>
          <PieChartComponent height={480} />
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card sx={cardStyles}>
          <Typography variant="h5" sx={{ mb: 3, color: '#4ECDC4', textAlign: 'center' }}>
            Estadísticas por Género
          </Typography>
          <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
            {genreAnalysis.map((genre, index) => (
              <Box 
                key={index} 
                sx={{ 
                  mb: 2, 
                  p: 2, 
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px'
                }}
              >
                <Typography variant="h6" sx={{ color: COLORS[index % COLORS.length] }}>
                  {genre.name}
                </Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ color: '#fff' }}>
                      Total: {genre.total}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#4ECDC4' }}>
                      Reproducidas: {genre.played}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ color: '#fff' }}>
                      Artistas: {genre.artistCount}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#fff' }}>
                      Canciones: {genre.songCount}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="body2" sx={{ color: '#4ECDC4', mt: 1 }}>
                  Tasa de reproducción: {genre.playRate}%
                </Typography>
              </Box>
            ))}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GenreAnalysis; 