import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

const BillingToggle = ({ billingCycle, handleToggle }) => (
  <ToggleButtonGroup 
    value={billingCycle} 
    exclusive 
    onChange={handleToggle}
    sx={{
      marginTop: 4,
      marginBottom: 3,
      border: '1px solid rgba(255, 255, 255, 0.12)',
      borderRadius: '28px',
      backgroundColor: 'rgba(68, 68, 68, 0.8)',
      padding: '4px',
      '& .MuiToggleButton-root': {
        border: 'none',
        borderRadius: '24px',
        color: 'rgba(255, 255, 255, 0.7)',
        padding: '8px 24px',
        textTransform: 'none',
        fontSize: '0.95rem',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(255, 255, 255, 0.16)',
          color: '#fff',
          fontWeight: 500,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
          }
        }
      }
    }}
  >
    <ToggleButton value="monthly">Mensual</ToggleButton>
    <ToggleButton value="yearly">Anual</ToggleButton>
  </ToggleButtonGroup>
);

export default BillingToggle; 