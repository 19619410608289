import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';

const ProfessionalInfoForm = ({ formData, errors, handleChange, handleNext, handleBack }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Información Profesional
      </Typography>

      <TextField
        fullWidth
        multiline
        rows={4}
        label="Biografía"
        name="bio"
        value={formData.bio}
        onChange={handleChange}
        error={!!errors.bio}
        helperText={errors.bio}
        sx={{ mb: 2 }}
      />

      <TextField
        fullWidth
        label="Años de experiencia"
        name="experiencia"
        type="number"
        value={formData.experiencia}
        onChange={handleChange}
        error={!!errors.experiencia}
        helperText={errors.experiencia}
        sx={{ mb: 2 }}
      />

      <TextField
        fullWidth
        label="Ubicación"
        name="lugar"
        value={formData.lugar}
        onChange={handleChange}
        error={!!errors.lugar}
        helperText={errors.lugar || "Ejemplo: Piura, Perú"}
        sx={{ mb: 2 }}
      />

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          fullWidth
          label="Precio Mínimo (S/.)"
          name="precioMin"
          type="number"
          value={formData.precioMin}
          onChange={handleChange}
          error={!!errors.precioMin}
          helperText={errors.precioMin}
        />
        <TextField
          fullWidth
          label="Precio Máximo (S/.)"
          name="precioMax"
          type="number"
          value={formData.precioMax}
          onChange={handleChange}
          error={!!errors.precioMax}
          helperText={errors.precioMax}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          sx={{ color: '#ffffff', borderColor: '#ffffff' }}
        >
          Atrás
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ backgroundColor: "#4caf50" }}
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};

export default ProfessionalInfoForm; 