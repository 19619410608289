import React from 'react';
import { Paper, Box, Typography, Button, IconButton, CircularProgress } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import MicIcon from '@mui/icons-material/Mic';

const KaraokeSelectedSongSection = ({
  selectedSong,
  singerName,
  handleCancel,
  handleSubmit,
  loading,
  disabled,
}) => (
  <Paper elevation={1} sx={{ padding: 2, marginTop: -1, marginBottom: 2 }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography variant="subtitle1" sx={{ color: 'rgb(255, 165, 0)' }}>
          Canción seleccionada
        </Typography>
        <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
          {selectedSong.title} <span style={{ fontWeight: 'normal' }}>por</span> {selectedSong.artist.name}
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'white', 
            marginTop: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <MicIcon sx={{ fontSize: '1.2rem' }} />
          <span>Cantante:</span> {singerName}
        </Typography>
      </Box>
      <IconButton 
        onClick={handleCancel} 
        aria-label="cancel"
        sx={{
          color: '#fff',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          }
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
    <Button
      fullWidth
      variant="contained"
      onClick={handleSubmit}
      sx={{ 
        marginTop: 2, 
        backgroundColor: '#54A772',
        '&:hover': {
          backgroundColor: '#458c5f',
        },
        '&:disabled': {
          backgroundColor: 'rgba(84, 167, 114, 0.5)',
        }
      }}
      disabled={loading || disabled}
    >
      {loading ? <CircularProgress size={24} /> : 'Agregar a la lista de karaoke'}
    </Button>
  </Paper>
);

export default KaraokeSelectedSongSection;
