import React, { useMemo } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const DJForm = ({ formData, errors, handleChange, handleFileUpload, handleNext }) => {
  // Estilos comunes memorizados para los TextField
  const textFieldStyles = useMemo(() => ({
    "& .MuiOutlinedInput-root": {
      color: "#ffffff",
      "& .MuiOutlinedInput-notchedOutline": { borderColor: "#C2C2C2" },
    },
  }), []);

  // Estilos memorizados para PhoneInput
  const phoneInputStyles = useMemo(() => ({
    inputStyle: {
      width: '100%',
      backgroundColor: '#1E1E1E',
      color: '#ffffff',
      borderColor: '#C2C2C2',
      borderRadius: '4px',
      padding: '10px',
      paddingLeft: '50px',
      transition: 'all 0.3s ease',
    },
    buttonStyle: {
      backgroundColor: '#333',
      color: '#ffffff',
      borderRadius: '4px 0 0 4px',
      position: 'absolute',
      transition: 'background-color 0.3s ease, color 0.3s ease',
    },
    containerStyle: {
      position: 'relative',
      width: '100%',
    },
    dropdownStyle: {
      backgroundColor: '#222',
      color: '#929292',
      border: '1px solid #444',
      borderRadius: '4px',
      transition: 'background-color 0.3s ease',
      zIndex: 9999,
    }
  }), []);

  const handlePhoneChange = (phone) => {
    handleChange({ target: { name: 'phone', value: phone } });
  };

  const renderTextField = (name, label) => (
    <TextField
      fullWidth
      label={label}
      name={name}
      value={formData[name]}
      onChange={handleChange}
      error={!!errors[name]}
      helperText={errors[name]}
      sx={textFieldStyles}
    />
  );

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#121212",
        borderRadius: 2,
        p: 3,
        boxShadow: "0 4px 10px rgba(0,0,0,0.5)",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ marginBottom: 2, color: "#ffffff", textAlign: "center" }}
      >
        Completa tu Información
      </Typography>

      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid item xs={12}>
          {renderTextField("djName", "Nombre de DJ")}
        </Grid>
        <Grid item xs={12}>
          {renderTextField("instagramLink", "Link de Instagram")}
        </Grid>
        
        <Grid item xs={12}>
          <PhoneInput
            country="pe"
            value={formData.phone}
            onChange={handlePhoneChange}
            {...phoneInputStyles}
            error={!!errors.phone}
          />
          {errors.phone && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {errors.phone}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          {renderTextField("email", "Correo Electrónico")}
        </Grid>
      </Grid>

      <Button
        variant="contained"
        component="label"
        fullWidth
        sx={{
          mt: 2,
          mb: 2,
          backgroundColor: "#115beb",
          color: "#ffffff",
          fontWeight: "bold",
        }}
      >
        <PhotoCameraIcon sx={{ mr: 1 }} />
        Cargar Foto
        <input type="file" accept="image/*" hidden onChange={handleFileUpload} />
      </Button>

      {formData.photo && (
        <Box sx={{ textAlign: "center", my: 2 }}>
          <Typography variant="body2" sx={{ color: "green", fontWeight: "bold" }}>
            ¡Archivo cargado con éxito!
          </Typography>
          <Typography variant="body2" sx={{ color: "#555" }}>
            Archivo: {formData.photo.name}
          </Typography>
        </Box>
      )}

      <Button
        variant="contained"
        fullWidth
        onClick={() => handleNext({ phone: formData.phone })}
        sx={{
          backgroundColor: "#4caf50",
          color: "#ffffff",
          fontWeight: "bold",
          "&:hover": { backgroundColor: "#0d47a1" },
        }}
      >
        Siguiente
      </Button>
    </Box>
  );
};

export default DJForm;