import React from 'react';
import { Card, CardContent, Typography, Divider, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';

const PlanCard = ({ plan, features, handleOpenModal, color = "#b70a20" }) => (
  <Card sx={{ 
    width: "100%",
    maxWidth: { xs: "100%", sm: 400 },
    margin: '0 auto', 
    padding: { xs: 2, sm: 3 },
    boxShadow: '0 8px 16px rgba(0,0,0,0.3)', 
    borderRadius: '16px', 
    backgroundColor: '#222', 
    color: 'white', 
    mt: 4 
  }}>
    <CardContent>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        {plan.title}
      </Typography>
      <Typography variant="h5" sx={{ color: color, fontWeight: 'bold' }}>
        {plan.price}
      </Typography>
      <Typography variant="subtitle1">{plan.subtitle}</Typography>
      <Divider sx={{ marginY: 2, backgroundColor: '#444' }} />
      <List>
        {features.map((feature, index) => (
          <ListItem key={index} sx={{ paddingY: 0.5 }}>
            <ListItemIcon sx={{ color: color }}>{feature.icon}</ListItemIcon>
            <ListItemText primary={feature.text} />
          </ListItem>
        ))}
      </List>
      <Button variant="contained" onClick={handleOpenModal} fullWidth sx={{ marginTop: 2, color: '#ffffff', backgroundColor: color, fontWeight: 'bold', '&:hover': { backgroundColor: `${color}dd` } }}>
        Comprar suscripción
      </Button>
    </CardContent>
  </Card>
);

export default PlanCard; 