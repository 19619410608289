import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';

const MultimediaForm = ({ formData, errors, handleFileUpload, handleNext, handleBack }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Fotos de Perfil y Portada
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          component="label"
          fullWidth
          startIcon={<PhotoCameraIcon />}
          sx={{
            mb: 1,
            backgroundColor: formData.profilePhoto ? "#4caf50" : "#115beb",
          }}
        >
          {formData.profilePhoto ? "Cambiar Foto de Perfil" : "Subir Foto de Perfil"}
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => handleFileUpload(e, 'profilePhoto')}
          />
        </Button>
        {errors.profilePhoto && (
          <Typography color="error" variant="caption">
            {errors.profilePhoto}
          </Typography>
        )}
        {formData.profilePhotoPreview && (
          <Box sx={{ mt: 1, textAlign: 'center' }}>
            <img
              src={formData.profilePhotoPreview}
              alt="Vista previa del perfil"
              style={{ maxWidth: '200px', borderRadius: '8px' }}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          component="label"
          fullWidth
          startIcon={<ImageIcon />}
          sx={{
            mb: 1,
            backgroundColor: formData.coverPhoto ? "#4caf50" : "#115beb",
          }}
        >
          {formData.coverPhoto ? "Cambiar Foto de Portada" : "Subir Foto de Portada (Opcional)"}
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => handleFileUpload(e, 'coverPhoto')}
          />
        </Button>
        {formData.coverPhotoPreview && (
          <Box sx={{ mt: 1, textAlign: 'center' }}>
            <img
              src={formData.coverPhotoPreview}
              alt="Vista previa de portada"
              style={{ maxWidth: '200px', borderRadius: '8px' }}
            />
          </Box>
        )}
        <Typography 
          variant="caption" 
          sx={{ 
            color: '#999',
            display: 'block',
            mt: 1,
            textAlign: 'center'
          }}
        >
          La foto de portada es opcional y aparecerá en tu perfil público
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          sx={{ color: '#ffffff', borderColor: '#ffffff' }}
        >
          Atrás
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ backgroundColor: "#4caf50" }}
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};

export default MultimediaForm; 