import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const cardStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 20px rgba(0,0,0,0.2)'
  }
};

const StatCard = ({ value, label, color = '#4ECDC4', subtext, icon }) => (
  <Card sx={cardStyles}>
    <CardContent sx={{ textAlign: 'center' }}>
      <Typography variant="h4" sx={{ color, mb: 1 }}>
        {value}
        {icon && (
          <Typography component="span" variant="h6" sx={{ color, ml: 1 }}>
            {icon}
          </Typography>
        )}
      </Typography>
      <Typography variant="body1" sx={{ color: '#fff' }}>
        {label}
      </Typography>
      {subtext && (
        <Typography variant="body2" sx={{ color, mt: 1 }}>
          {subtext}
        </Typography>
      )}
    </CardContent>
  </Card>
);

const GeneralStats = ({ statistics }) => {
  const notPlayedPercentage = ((statistics.totalRequests - statistics.playedSongs) / statistics.totalRequests * 100).toFixed(1);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.totalRequests}
          label="Total de Solicitudes"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.uniqueArtists}
          label="Artistas Únicos"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.uniqueSongs}
          label="Canciones Únicas"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.playedSongs}
          label="Canciones Reproducidas"
          icon="✅"
          subtext={`${statistics.playedPercentage} del total`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.totalRequests - statistics.playedSongs}
          label="Canciones No Reproducidas"
          color="#FF6B6B"
          icon="❌"
          subtext={`${notPlayedPercentage}% del total`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.songsPerDay}
          label="Promedio Diario"
        />
      </Grid>
    </Grid>
  );
};

export default GeneralStats; 