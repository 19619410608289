import React from 'react';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';

const StepperComponent = ({ steps, activeStep }) => (
  <Stepper
    activeStep={activeStep}
    alternativeLabel
    sx={{
      mb: 3,
      "& .MuiStepIcon-root": {
        color: "#444",
        borderRadius: "50%",
      },
      "& .MuiStepIcon-root.Mui-active": {
        color: "#b70a20",
      },
      "& .MuiStepIcon-root.Mui-completed": {
        color: "#4caf50",
      },
    }}
  >
    {steps.map((step, index) => (
      <Step key={index}>
        <StepLabel>
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            {step.title}
          </Typography>
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

export default StepperComponent; 