import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, useTheme, IconButton } from "@mui/material";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import StepperComponent from "../components/StepperComponent";
import GaleriaPreview from '../assets/galeria-preview.png';
import WhatsappPreview from '../assets/whatsapp-preview.png';
import UbicacionPreview from '../assets/ubicacion-preview.png';
import MusicaPreview from '../assets/musica-preview.png';
import BillingToggle from "../components/BillingToggle";
import PlanCard from "../components/PlanCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ShareIcon from "@mui/icons-material/Share";
import ProfileDJModal from "../components/ProfileDJProduct/ProfileDJModal";
import DJsSection from "../components/DJsSection";
import { getDJRecords } from '../api/airtable';

const CAROUSEL_FEATURES = [
  {
    image: GaleriaPreview,
    title: "Galería Visual",
    description: "Exhibe tus mejores fotos y videos en un diseño profesional y atractivo."
  },
  {
    image: WhatsappPreview,
    title: "Contacto Directo",
    description: "Botón integrado para contacto vía WhatsApp o redes sociales."
  },
  {
    image: UbicacionPreview,
    title: "Ubicación y Eventos",
    description: "Muestra dónde trabajas y tus próximos eventos."
  },
  {
    image: MusicaPreview,
    title: "Tu Música",
    description: "Comparte tus mezclas y sets favoritos directamente desde tu perfil."
  }
];

const PLANS = {
  monthly: { price: "S/.7", title: "Plan Mensual", subtitle: "Ideal para empezar tu presencia online." },
  yearly: { price: "S/.70", title: "Plan Anual", subtitle: "Ahorra S/.14 con este plan exclusivo." },
};

const FEATURES = [
  { text: "Página personalizada profesional", icon: <CheckCircleIcon /> },
  { text: "Galería multimedia integrada", icon: <PhotoLibraryIcon /> },
  { text: "Sistema de contacto directo", icon: <WhatsAppIcon /> },
  { text: "Presencia online optimizada", icon: <ShareIcon /> },
];

const ProfileDJProduct = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDJModal, setOpenDJModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [djs, setDJs] = useState([]);

  const fetchDJs = useCallback(async () => {
    const records = await getDJRecords('DJs Bio');
    setDJs(records);
  }, []);

  useEffect(() => {
    fetchDJs();
  }, [fetchDJs]);

  const handleNext = useCallback(() => {
    setActiveStep((prevStep) => (prevStep + 1) % CAROUSEL_FEATURES.length);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevStep) => (prevStep - 1 + CAROUSEL_FEATURES.length) % CAROUSEL_FEATURES.length);
  }, []);

  const handleToggle = useCallback((_, value) => {
    if (value !== null) setBillingCycle(value);
  }, []);

  const handleOpenModal = useCallback(() => {
    const selectedPlanInfo = `${PLANS[billingCycle].title} - ${PLANS[billingCycle].price}`;
    setSelectedPlan(selectedPlanInfo);
    setOpenDJModal(true);
  }, [billingCycle]);

  return (
    <>
      <Navbar />
      <Box
        sx={{
          textAlign: "center",
          padding: { xs: 2, sm: 4 },
          backgroundColor: theme.palette.primary.main,
          color: "white",
          minHeight: "100vh",
          mt: { xs: 2, sm: 5 },
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "#ffffff",
            mb: 2,
            mt: { xs: 10, sm: 6 }
          }}
        >
          Tu Pagina Web como DJ
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mb: 4,
            color: "rgba(255,255,255,0.9)",
            maxWidth: "600px",
            margin: "0 auto",
            lineHeight: 1.5
          }}
        >
          Tu presencia profesional en línea con DJ Ponla
        </Typography>

        <BillingToggle 
          billingCycle={billingCycle} 
          handleToggle={handleToggle}
          sx={{
            '.MuiToggleButtonGroup-root': {
              background: 'rgba(255,255,255,0.05)',
              backdropFilter: 'blur(10px)',
              borderRadius: '15px',
              padding: '4px',
              border: '1px solid rgba(255,255,255,0.1)',
            },
            '.MuiToggleButton-root': {
              border: 'none',
              borderRadius: '12px !important',
              color: 'rgba(255,255,255,0.7)',
              padding: '8px 24px',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)',
              },
              '&.Mui-selected': {
                backgroundColor: '#b70a20',
                color: '#fff',
                fontWeight: 'bold',
                boxShadow: '0 4px 12px rgba(183, 10, 32, 0.3)',
                '&:hover': {
                  backgroundColor: '#d32f2f',
                }
              }
            }
          }}
        />

        <PlanCard 
          plan={PLANS[billingCycle]} 
          features={FEATURES} 
          handleOpenModal={handleOpenModal} 
          color="#b70a20" 
          sx={{
            transform: "scale(1.05)",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.08)",
            },
            boxShadow: "0 8px 32px rgba(0,0,0,0.2)"
          }}
        />

        <Box sx={{ 
          mt: 8, 
          mb: 6,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(180deg, rgba(183, 10, 32, 0.1) 0%, rgba(17, 91, 235, 0.1) 100%)',
            borderRadius: '20px',
            zIndex: -1,
          }
        }}>

          <DJsSection djs={djs} />
        </Box>

        <Box sx={{ 
          position: 'relative', 
          my: 4,
          maxWidth: "800px",
          margin: "0 auto",
          px: { xs: 4, sm: 6 },
          mt: { xs: 4, sm: 6 }
        }}>
          <StepperComponent steps={CAROUSEL_FEATURES} activeStep={activeStep} />
          
          <motion.div
            key={activeStep}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.5 }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.05)",
                p: 4,
                borderRadius: 2,
                my: 4,
                height: { xs: "500px", sm: "600px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backdropFilter: "blur(10px)",
              }}
            >
              <Box
                component="img"
                src={CAROUSEL_FEATURES[activeStep].image}
                alt={CAROUSEL_FEATURES[activeStep].title}
                sx={{
                  width: '100%',
                  height: { xs: "300px", sm: "400px" },
                  objectFit: "contain",
                  borderRadius: '12px',
                  boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
                  mb: 3,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
              />
              <Typography 
                variant="h5" 
                gutterBottom 
                sx={{ 
                  mt: 3, 
                  fontWeight: "bold",
                  color: "#b70a20",
                }}
              >
                {CAROUSEL_FEATURES[activeStep].title}
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: "rgba(255,255,255,0.8)",
                  maxWidth: "600px",
                  textAlign: "center",
                }}
              >
                {CAROUSEL_FEATURES[activeStep].description}
              </Typography>
            </Box>
          </motion.div>

          <IconButton
            onClick={handleBack}
            sx={{
              position: "absolute",
              left: { xs: 0, sm: 0 },
              top: "50%",
              transform: "translateY(-50%)",
              color: "white",
              zIndex: 1,
              backgroundColor: "rgba(0,0,0,0.3)",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.5)",
              },
              width: 40,
              height: 40,
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: "50%",
              transform: "translateY(-50%)",
              color: "white",
              zIndex: 1,
              backgroundColor: "rgba(0,0,0,0.3)",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.5)",
              },
              width: 40,
              height: 40,
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <ProfileDJModal
          open={openDJModal}
          onClose={() => setOpenDJModal(false)}
          selectedPrice={PLANS[billingCycle].price}
        />
      </Box>
    </>
  );
};

export default ProfileDJProduct; 