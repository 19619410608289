import React from "react";
import { Box, Typography, Card, CardContent, Button, Grid, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import QrCodeIcon from "@mui/icons-material/QrCode";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { motion } from "framer-motion";
import CheckIcon from '@mui/icons-material/Check';

const Pricing = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleQRDJClick = () => {
    navigate("/qr-dj-product");
  };

  const handleProfileDJClick = () => {
    navigate("/profile-dj-product");
  };

  const products = [
    {
      icon: <QrCodeIcon sx={{ 
        fontSize: isMobile ? 70 : 90, 
        color: '#ffffff',
        mb: { xs: 2, sm: 3 },
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "scale(1.1)"
        }
      }} />,
      title: "QR DJ",
      price: {
        amount: "35",
        currency: "S/.",
        period: "/mes",
        prefix: "Desde"
      },
      description: "Sistema de petición de canciones mediante código QR para DJs",
      features: [
        { icon: <CheckIcon fontSize="small" />, text: "Código QR único para cada evento" },
        { icon: <CheckIcon fontSize="small" />, text: "Recibe peticiones en tiempo real" },
        { icon: <CheckIcon fontSize="small" />, text: "Interfaz intuitiva para el público" },
        { icon: <CheckIcon fontSize="small" />, text: "Control total sobre la playlist" }
      ],
      onClick: handleQRDJClick,
      color: "#b70a20"
    },
    {
      icon: <AccountCircleIcon sx={{ 
        fontSize: isMobile ? 70 : 90, 
        color: '#ffffff',
        mb: { xs: 2, sm: 3 },
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "scale(1.1)"
        }
      }} />,
      title: "Página web DJ",
      price: {
        amount: "7",
        currency: "S/.",
        period: "/mes",
        prefix: "Desde"
      },
      description: "Tu presencia profesional en línea como DJ",
      features: [
        { icon: <CheckIcon fontSize="small" />, text: "Página personalizada profesional" },
        { icon: <CheckIcon fontSize="small" />, text: "Galería multimedia integrada" },
        { icon: <CheckIcon fontSize="small" />, text: "Sistema de contacto directo" },
        { icon: <CheckIcon fontSize="small" />, text: "Presencia online optimizada" }
      ],
      onClick: handleProfileDJClick,
      color: "#b70a20"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 50
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <>
      <Navbar />
      <Box
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        sx={{
          textAlign: "center",
          padding: { xs: 1, sm: 4 },
          backgroundColor: theme.palette.primary.main,
          color: "white",
          minHeight: "100vh",
          mt: { xs: 10, sm: 8 },
          overflow: 'hidden',
          width: '100%'
        }}
      >

        <Typography 
          variant={isMobile ? "h5" : "h4"} 
          gutterBottom 
          sx={{ 
            mt: 6, 
            fontWeight: "bold",
            mb: 4
          }}
        >
          Nuestros Productos
        </Typography>
        
        <Grid 
          container 
          spacing={2}
          justifyContent="center"
          component={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          sx={{ 
            maxWidth: "1000px", 
            margin: "0 auto",
            px: { xs: 0, sm: 4 },
            width: '100%'
          }}
        >
          {products.map((product, index) => (
            <Grid 
              item 
              xs={12} 
              sm={6} 
              md={5} 
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <motion.div 
                variants={cardVariants}
                style={{
                  width: '100%',
                  maxWidth: { xs: '95%', sm: '400px' },
                  margin: '0 auto'
                }}
              >
                <Card 
                  sx={{ 
                    width: '100%',
                    maxWidth: { xs: '95%', sm: '400px' },
                    mx: 'auto',
                    backgroundColor: "rgba(34, 34, 34, 0.9)",
                    backdropFilter: "blur(10px)",
                    color: "white",
                    cursor: "pointer",
                    transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
                    position: "relative",
                    overflow: "hidden",
                    "&:hover": {
                      transform: "translateY(-10px) scale(1.02)",
                      boxShadow: `0 20px 40px -15px ${product.color}80`,
                      "& .hover-gradient": {
                        opacity: 1
                      },
                      "& .card-content": {
                        transform: "translateY(-5px)"
                      },
                      "& .feature-icon": {
                        color: product.color,
                        transform: "scale(1.1)"
                      }
                    },
                    borderRadius: 3,
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: `linear-gradient(135deg, ${product.color}15, transparent 50%, ${product.color}10)`,
                      opacity: 0.1,
                      transition: "opacity 0.4s ease"
                    },
                    "&:hover::before": {
                      opacity: 0.3
                    }
                  }}
                  onClick={product.onClick}
                >
                  <CardContent 
                    className="card-content"
                    sx={{ 
                      position: "relative", 
                      zIndex: 1, 
                      pt: 3,
                      px: { xs: 2, sm: 3 },
                      transition: "transform 0.3s ease"
                    }}
                  >
                    {product.icon}

                    <Typography 
                      variant={isMobile ? "h4" : "h3"}
                      gutterBottom
                      sx={{ 
                        fontWeight: "bold",
                        mb: { xs: 1, sm: 2 },
                        fontSize: { 
                          xs: "1.75rem", 
                          sm: "2rem", 
                          md: "2.5rem" 
                        },
                        color: '#ffffff'
                      }}
                    >
                      {product.title}
                    </Typography>

                    <Box sx={{ 
                      mb: { xs: 2, sm: 3 },
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: '#ffffff',
                          fontWeight: "500",
                          fontSize: { xs: "0.8rem", sm: "0.9rem" },
                          mb: 0.5
                        }}
                      >
                        {product.price.prefix}
                      </Typography>
                      <Box sx={{ 
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: 0.5
                      }}>
                        <Typography 
                          variant="h6"
                          sx={{ 
                            color: '#ffffff',
                            fontWeight: "500",
                            fontSize: { xs: "1rem", sm: "1.1rem" }
                          }}
                        >
                          {product.price.currency}
                        </Typography>
                        <Typography 
                          variant="h3" 
                          sx={{ 
                            color: '#ffffff',
                            fontWeight: "bold",
                            lineHeight: 1,
                            fontSize: { xs: "2.5rem", sm: "3rem" }
                          }}
                        >
                          {product.price.amount}
                        </Typography>
                        <Typography 
                          variant="subtitle1"
                          sx={{ 
                            color: '#ffffff',
                            fontWeight: "500",
                            opacity: 0.8,
                            fontSize: { xs: "0.9rem", sm: "1rem" }
                          }}
                        >
                          {product.price.period}
                        </Typography>
                      </Box>
                    </Box>

                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: { xs: 2, sm: 3 },
                        opacity: 0.9,
                        px: { xs: 1, sm: 2 },
                        fontSize: { xs: "0.9rem", sm: "1rem" }
                      }}
                    >
                      {product.description}
                    </Typography>

                    <Box sx={{ 
                      width: '100%',
                      mb: { xs: 2, sm: 3 },
                      display: 'flex',
                      flexDirection: 'column',
                      gap: { xs: 0.5, sm: 1 }
                    }}>
                      {product.features.map((feature, idx) => (
                        <Typography 
                          key={idx} 
                          variant="body2" 
                          sx={{ 
                            color: '#aaa',
                            fontSize: { xs: "0.85rem", sm: "0.9rem" },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            py: 0.5,
                            transition: "all 0.3s ease",
                            "&:hover": {
                              color: "white",
                              backgroundColor: `${product.color}15`,
                              transform: "translateX(5px)"
                            },
                            '& .MuiSvgIcon-root': {
                              color: `${product.color}90`,
                              transition: "all 0.3s ease",
                              fontSize: { xs: "1rem", sm: "1.2rem" }
                            }
                          }}
                        >
                          <span className="feature-icon">{feature.icon}</span>
                          {feature.text}
                        </Typography>
                      ))}
                    </Box>

                    <Button 
                      variant="contained" 
                      sx={{ 
                        mt: 'auto',
                        backgroundColor: product.color,
                        "&:hover": {
                          backgroundColor: `${product.color}`,
                          transform: "scale(1.05)",
                          boxShadow: `0 8px 20px -5px ${product.color}90`
                        },
                        transition: "all 0.3s ease",
                        borderRadius: 2,
                        px: { xs: 3, sm: 4 },
                        py: { xs: 0.75, sm: 1 },
                        fontSize: { xs: "0.9rem", sm: "1rem" },
                        fontWeight: "bold"
                      }}
                    >
                     ¡Lo quiero!
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Pricing;