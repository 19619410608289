import React, { useState } from 'react';
import {
  Card, CardContent, Typography, Button, Box, CircularProgress, useMediaQuery
} from '@mui/material';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { motion, useMotionValue } from 'framer-motion';

const SongCard = ({ song, handleDelete, loading, isNew, showTableNumber }) => {
  const songKey = `${song.fields['Song Name']} - ${song.fields['Artist']}`;
  const [isDragging, setIsDragging] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  // Motion value for x-axis dragging
  const x = useMotionValue(0);

  const handleButtonClick = (action) => {
    handleDelete(songKey, action);
  };

  const handleDragEnd = (event, info) => {
    if (info.offset.x < -100) {
      handleButtonClick('No la quiero poner');
    }
    setIsDragging(false);
  };

  return (
    <motion.div
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={handleDragEnd}
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
        x,
      }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 1 }}
      animate={{ opacity: loading ? 0 : 1 }}
      transition={{ duration: 0.5 }}
    >
      <Card
        component={motion.div}
        sx={{
          borderRadius: 5,
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '150px',
        }}
        style={{
          backgroundColor: '#ffffff',
          color: '#fff',
        }}
      >
        {song.count > 1 && (
          <Box sx={{ position: 'absolute', top: 8, right: 8, display: 'flex', alignItems: 'center', color: '#f44336' }}>
            <WhatshotIcon fontSize="large" sx={{ fontSize: '2rem' }} />
            <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold', fontSize: '1rem' }}>
              x{song.count}
            </Typography>
          </Box>
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', fontSize: '1.1rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', pr: 4 }}>
              {song.fields['Song Name']}
            </Typography>
          </Box>
          <Typography variant="subtitle1" color="primary">
            {song.fields['Artist']}
          </Typography>
          {showTableNumber && song.fields['Table Number'] && (
            <Typography 
              variant="body2" 
              sx={{ 
                color: '#424242',
                opacity: 0.8,
                marginTop: 1,
                fontWeight: 'bold'
              }}
            >
              Mesa: {song.fields['Table Number']}
            </Typography>
          )}
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 3,
                  flexBasis: '48%',
                  minHeight: '45px',
                  fontSize: isMobile ? '0.9rem' : '1rem',
                  backgroundColor: '#F44236',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                  },
                }}
                onClick={() => handleButtonClick('No la quiero poner')}
                disabled={loading}
              >
                <CloseRoundedIcon sx={{ marginRight: '0.5rem', fontSize: '1.5rem', backgroundColor: '#fff', borderRadius: '50%', padding: '0.2rem', color: '#F44236' }} />
                No la voy a poner
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 3,
                  flexBasis: '48%',
                  backgroundColor: '#54A773',
                  color: '#fff',
                  minHeight: '45px',
                  transition: 'background-color 0.3s ease',
                  fontSize: isMobile ? '0.9rem' : '1rem',
                  ml: 1,
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: '#388e3c',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                  },
                }}
                onClick={() => handleButtonClick('Se puso')}
                disabled={loading}
              >
                <CheckRoundedIcon sx={{ marginRight: '0.5rem', fontSize: '1.5rem', backgroundColor: '#fff', borderRadius: '50%', padding: '0.2rem', color: '#54A773' }} />
                Ya la puse
              </Button>
            </>
          )}
        </Box>
      </Card>
    </motion.div>
  );
};

export default SongCard;
