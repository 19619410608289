import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { getDJRecords, deleteDJRecord, saveSongRequest, getDJInfo } from '../api/airtable';
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import SongCard from '../components/DJView/SongCard';
import LogoExtendido from '../assets/Logo DJ Ponla extendido (sin fondo).png';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { motion } from 'framer-motion';

const DJView = () => {
  const { djId } = useParams();
  const [songs, setSongs] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [loadingSongs, setLoadingSongs] = useState({});
  const [error, setError] = useState(null);
  const [djInfo, setDjInfo] = useState({});
  const [currentMessage, setCurrentMessage] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const messages = [
    '🎧 Recuerda marcar las canciones que ya tocaste 😎',
    '⬅️ Desliza a la izquierda las canciones que no te interesen 🎶',
    '😎 Aún no hay canciones, pero pronto tendrás una lista increíble',
    '💪 Recuerda, tú controlas la música. ¡Hazlo épico!',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => (prevMessage + 1) % messages.length);
    }, 5000); // Cambia cada 5 segundos

    return () => clearInterval(interval);
  }, [messages.length]);

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        const records = await getDJRecords(djId);
        setSongs(
          records.sort(
            (a, b) => new Date(b.fields['Created']) - new Date(a.fields['Created'])
          )
        );
      } catch (error) {
        console.error('Error fetching songs:', error);
        setError(
          'Hubo un error al cargar las canciones. Por favor, inténtalo de nuevo.'
        );
      }
    };

    fetchSongs();

    const interval = setInterval(fetchSongs, 8000); // Actualiza cada 8 segundos
    return () => clearInterval(interval);
  }, [djId]);

  useEffect(() => {
    const fetchDJInfo = async () => {
      try {
        const info = await getDJInfo(djId);
        setDjInfo(info);
      } catch (error) {
        console.error('Error fetching DJ info:', error);
      }
    };
    fetchDJInfo();
  }, [djId]);

  const handleDelete = async (songKey, option = '') => {
    const songsToDelete = songs.filter((song) => {
      const key = `${song.fields['Song Name']} - ${song.fields['Artist']}`;
      return key === songKey;
    });

    const newTableId = djId.replace(/^f|m$/g, '');

    for (const song of songsToDelete) {
      setLoadingSongs((prevState) => ({ ...prevState, [song.id]: true }));

      try {
        if (djId === 'fm0924tom') {
          await saveSongRequest(
            newTableId,
            song.fields['Song Name'],
            song.fields['Artist'],
            song.fields['Created'],
            song.fields['Genre'],
            option,
            song.fields['Table Number'],
          );
        } else {
          await saveSongRequest(
            newTableId,
            song.fields['Song Name'],
            song.fields['Artist'],
            song.fields['Created'],
            song.fields['Genre'],
            option,
          );
        }
        await deleteDJRecord(djId, song.id);
      } catch (error) {
        console.error('Error deleting song:', error);
      } finally {
        setLoadingSongs((prevState) => ({ ...prevState, [song.id]: false }));
      }
    }

    setSongs((prevSongs) => prevSongs.filter((song) => {
      const key = `${song.fields['Song Name']} - ${song.fields['Artist']}`;
      return key !== songKey;
    }));
  };

  const handleFilter = useCallback(() => {
    setIsFiltered((prev) => !prev);
  }, []);

  const groupedSongs = useMemo(() => {
    const songMap = {};
    songs.forEach((song) => {
      const key = `${song.fields['Song Name']} - ${song.fields['Artist']}`;
      if (!songMap[key]) {
        songMap[key] = { ...song, count: 0 };
      }
      songMap[key].count += 1;
    });
  
    return Object.values(songMap).sort((a, b) => {
      if (b.count !== a.count) {
        return b.count - a.count; // Ordena por número de repeticiones
      }
      return new Date(b.fields['Created']) - new Date(a.fields['Created']); // Ordena por fecha de llegada
    });
  }, [songs]);

  const filteredSongs = useMemo(() => {
    const songsToFilter = isFiltered ? [...groupedSongs].sort((a, b) =>
      a.fields['Artist'].localeCompare(b.fields['Artist'])
    ) : groupedSongs;
    return songsToFilter;
  }, [groupedSongs, isFiltered]);
  

  // Función para determinar si una canción es nueva (agregada en los últimos 5 minutos)
  const isNewSong = (createdDate) => {
    const now = new Date();
    const songDate = new Date(createdDate);
    const diffInMinutes = (now - songDate) / (1000 * 60);
    return diffInMinutes <= 5; // Puedes ajustar el tiempo según tus necesidades
  };


  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#121212', color: '#fff', py: 2 }}>
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
          <img
            src={LogoExtendido}
            alt='Logo DJPonla'
            style={{ width: '60%', height: 'auto', borderRadius: 10 }}
          />
        </Box>

        {/* Mensaje de Bienvenida Mejorado */}
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: isMobile ? '1.5rem' : '2rem',
              fontWeight: 'bold',
              mb: 1,
            }}
          >
            ¡Bienvenido, {djInfo.Name}!
          </Typography>
        </Box>

        {/* Aquí añadimos el texto con el total de canciones */}
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem', mr: 1 }}
          >
            La gente quiere escuchar
          </Typography>
        </Box>

        {/* Botón de filtro y contador de canciones */}
        <Box
          sx={{
            textAlign: 'center',
            mb: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<FilterListIcon />}
            sx={{ borderRadius: 5, fontSize: isMobile ? '0.8rem' : '1rem' }}
            onClick={handleFilter}
          >
            {isFiltered ? 'Mostrar todo' : 'Filtrar por Artista'}
          </Button>
          {/* Mostramos el ícono y el número de canciones al lado derecho del botón */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <LibraryMusicIcon sx={{ fontSize: isMobile ? '1.5rem' : '2rem' }} />
            <Typography variant="h6" sx={{ fontSize: isMobile ? '1rem' : '1.2rem' }}>
              {filteredSongs.length} 
            </Typography>
          </Box>
        </Box>

        {/* Mensaje dinámico para animar al DJ cuando no hay canciones */}
        {filteredSongs.length === 0 && (
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              color: '#fff',
              marginTop: 4,
            }}
          >
            <motion.div
              key={currentMessage}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{ marginTop: '1rem' }}
            >
              <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                {messages[currentMessage]}
              </Typography>
            </motion.div>
          </Box>
        )}

        {/* Lista de canciones */}
        <Grid container spacing={2}>
          {filteredSongs.map((song, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <SongCard
                song={song}
                handleDelete={handleDelete}
                loading={loadingSongs[song.id]}
                isNew={isNewSong(song.fields['Created'])}
                showTableNumber={djId === 'fm0924tom'}
              />
            </Grid>
          ))}
        </Grid>

        {error && (
          <Box textAlign="center" mt={4}>
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default DJView;
