import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {

  const navigate = useNavigate();
  const handlePricingClick = () => {
    navigate('/comprar');
  };
  
  return (
    <Box sx={{ textAlign: 'center', mt: 5, mb: 5 }}>
      <Typography variant="h4" gutterBottom>
        ¡Lleva tu fiesta al siguiente nivel!
      </Typography>
      <Typography variant="h6" sx={{ color: '#A6A5A6', mb: 4 }}>
      Mejoramos la conexión entre la gente y los DJs.
      </Typography>
      <Button 
        onClick={handlePricingClick} 
        variant="outlined" 
        color="secondary" 
        size="large"
        sx={{ color:'#ffffff', backgroundColor:'#115beb',borderRadius: 4, }}
      >
        ¡Comprar ahora!
      </Button>
    </Box>
  );
};

export default HeroSection;
