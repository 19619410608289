import React, { useState, lazy, Suspense, memo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Container,
  CircularProgress,
  Box,
  BottomNavigationAction,
} from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import useDjData from '../hooks/useDjData';
import { FloatingNav, GradientText } from '../components/DjProfile/StyledComponents';

// Lazy-loaded components
const BioSection = lazy(() => import('../components/DjProfile/BioSection'));
const MusicSection = lazy(() => import('../components/DjProfile/MusicSection'));
const GallerySection = lazy(() => import('../components/DjProfile/GallerySection'));
const BookingSection = lazy(() => import('../components/DjProfile/BookingSection'));
const AdditionalInfo = lazy(() => import('../components/DjProfile/AdditionalInfo'));

function DjProfile() {
  const { djName } = useParams();
  const { djData, loading, error } = useDjData(djName);

  const [state, setState] = useState({
    navValue: 'bio',
    bioExpanded: false,
    showAllLocales: false,
  });

  const handleBioExpandClick = () => {
    setState((prev) => ({ ...prev, bioExpanded: !prev.bioExpanded }));
  };

  const { navValue, bioExpanded, showAllLocales } = state;

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress sx={{color:'#fff'}} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" align="center" sx={{ mt: 4 }}>
        Error al cargar los datos del DJ.
      </Typography>
    );
  }

  const photoUrl = djData.photo?.[0]?.url || null;
  const coverPhotoUrl = djData.coverPhotoUrl?.[0]?.url || null;

  const {
    Lugar,
    Experiencia,
    'Precio Min': precioMin,
    'Precio Max': precioMax,
    'Nombre Local': nombreLocal = '',
    youtubeUrl,
  } = djData || {};

  return (
    <div>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        {coverPhotoUrl ? (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '180px',
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url(${coverPhotoUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 3,
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              pb: '40px',
              marginBottom: '4rem',
            }}
          >
            {photoUrl && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-60px',
                  width: '180px',
                  height: '180px',
                  backgroundColor: '#fff',
                  backgroundImage: `url(${photoUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                  borderRadius: '2rem',
                }}
              />
            )}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
            {photoUrl && (
              <Box
                sx={{
                  width: '160px',
                  height: '160px',
                  backgroundColor: '#fff',
                  backgroundImage: `url(${photoUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                  borderRadius: '2rem',
                }}
              />
            )}
          </Box>
        )}

        <GradientText variant="h4" align="center" gutterBottom sx={{ mt: 2, fontWeight: 'bold' }}>
          {djData.name}
        </GradientText>

        <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
          {djData.tagline}
        </Typography>

        <Suspense fallback={<CircularProgress />}>
          <AdditionalInfo
            Lugar={Lugar}
            Experiencia={Experiencia}
            precioMin={precioMin}
            precioMax={precioMax}
            nombreLocal={nombreLocal}
            instagramUrl={djData.instagram}
            facebookUrl={djData.facebook}
            tiktokUrl={djData.tiktok}
            spotifyUrl={djData.spotify}
          />
          <BioSection
            bio={djData.bio}
            bioExpanded={bioExpanded}
            handleBioExpandClick={handleBioExpandClick}
          />
          <MusicSection musicUrl={djData.musicUrl} youtubeUrl={youtubeUrl} />
          <GallerySection gallery={djData.gallery} />
          <BookingSection selectedColor="#D32F2F" djname={djData.name} phone={djData.Phone} />
        </Suspense>
      </Container>

      <FloatingNav
        value={navValue}
        onChange={(event, newValue) => {
          setState((prev) => ({ ...prev, navValue: newValue }));
          const section = document.getElementById(newValue);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }}
      >
        <BottomNavigationAction
          value="bio"
          icon={<PersonRoundedIcon fontSize="medium" />}
          sx={{
            color: navValue === 'bio' ? '#D32F2F' : 'inherit',
          }}
        />
        <BottomNavigationAction
          value="music"
          icon={<MusicNoteIcon fontSize="medium" />}
          sx={{
            color: navValue === 'music' ? '#D32F2F' : 'inherit',
          }}
        />
        <BottomNavigationAction
          value="gallery"
          icon={<PhotoLibraryIcon fontSize="medium" />}
          sx={{
            color: navValue === 'gallery' ? '#D32F2F' : 'inherit',
          }}
        />
        <BottomNavigationAction
          value="booking"
          icon={<ContactMailIcon fontSize="medium" />}
          sx={{
            color: navValue === 'booking' ? '#D32F2F' : 'inherit',
          }}
        />
      </FloatingNav>
    </div>
  );
}

export default memo(DjProfile);
