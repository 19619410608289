import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  LinearProgress,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const cardStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  p: 3,
  minHeight: { xs: 'auto', md: '600px' }
};

const ArtistAnalysis = ({ artistAnalysis }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const MobileArtistList = () => (
    <List sx={{ width: '100%', color: '#fff' }}>
      {artistAnalysis.map((artist, index) => (
        <ListItem 
          key={artist.name}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderBottom: '1px solid rgba(255,255,255,0.1)',
            py: 2
          }}
        >
          <Box sx={{ width: '100%', mb: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {index + 1}. {artist.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              {artist.requests} solicitudes · {artist.uniqueSongs} canciones únicas
            </Typography>
          </Box>
          
          <Box sx={{ width: '100%', mb: 1 }}>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Reproducidas: {artist.played} ({artist.playRate}%)
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={parseFloat(artist.playRate)} 
              sx={{
                backgroundColor: 'rgba(255,255,255,0.1)',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#4ECDC4'
                },
                borderRadius: 1,
                height: 8
              }}
            />
          </Box>

          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              No reproducidas: {artist.notPlayed}
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={(artist.notPlayed / artist.requests) * 100} 
              sx={{
                backgroundColor: 'rgba(255,255,255,0.1)',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#FF6B6B'
                },
                borderRadius: 1,
                height: 8
              }}
            />
          </Box>
        </ListItem>
      ))}
    </List>
  );

  const DesktopChart = () => (
    <Box sx={{ height: 500 }}>
      <ResponsiveContainer>
        <BarChart
          data={artistAnalysis}
          margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
          barGap={0}
          barCategoryGap="20%"
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
          <XAxis 
            dataKey="name" 
            angle={45} 
            textAnchor="start" 
            height={100} 
            stroke="#fff"
            interval={0}
            tick={{ fill: '#fff', fontSize: 12 }}
          />
          <YAxis 
            stroke="#fff"
            tick={{ fill: '#fff' }}
          />
          <Tooltip 
            contentStyle={{ 
              backgroundColor: '#1e1e1e', 
              border: 'none',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.3)'
            }}
            labelStyle={{ color: '#fff', fontWeight: 'bold' }}
            itemStyle={{ color: '#fff' }}
            formatter={(value, name) => [
              `${value} canciones`,
              name === "played" ? "Reproducidas ✅" : "No Reproducidas ❌"
            ]}
          />
          <Legend 
            verticalAlign="top" 
            height={36}
            wrapperStyle={{ color: '#fff' }}
            formatter={(value) => (
              value === "played" ? "Reproducidas ✅" : "No Reproducidas ❌"
            )}
          />
          <Bar 
            name="played" 
            dataKey="played" 
            fill="#4ECDC4" 
            radius={[4, 4, 0, 0]}
          />
          <Bar 
            name="notPlayed" 
            dataKey="notPlayed" 
            fill="#FF6B6B"
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={cardStyles}>
          <Typography variant="h5" sx={{ 
            mb: 4, 
            color: '#4ECDC4', 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', md: '1.5rem' }
          }}>
            Top 10 Artistas Más Solicitados
          </Typography>
          
          {isMobile ? <MobileArtistList /> : <DesktopChart />}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ArtistAnalysis; 