import Airtable from 'airtable';

const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID;
const base = new Airtable({ apiKey }).base(baseId);

/* ----------------------------------------
   Funciones relacionadas con DJ
---------------------------------------- */

// Función para obtener los registros de canciones del DJ
export const getDJRecords = async (djViewId) => {
  return new Promise((resolve, reject) => {
    const recordsArray = [];
    base(djViewId)
      .select({
        maxRecords: 100,
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            recordsArray.push({ id: record.id, fields: record.fields });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error('Error al obtener los registros del DJ:', err);
            reject(err);
          } else {
            resolve(recordsArray);
          }
        }
      );
  });
};

// Función para crear una solicitud de canción para el DJ
export const createSongRequest = async (
  formId,
  songTitle,
  artistName,
  genre,
  tableNumber = null
) => {
  return new Promise((resolve, reject) => {
    const fields = {
      'Song Name': songTitle,
      'Artist': artistName,
      'Genre': genre,
    };

    if (tableNumber) {
      fields['Table Number'] = tableNumber;
    }

    base(formId).create(
      [
        {
          fields: fields
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al crear la solicitud de canción:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para guardar una solicitud de canción para el DJ (al mover canciones entre tablas)
export const saveSongRequest = async (
  tableId,
  songName,
  artist,
  created,
  genre,
  option = '',
  tableNumber = null
) => {
  return new Promise((resolve, reject) => {
    const fields = {
      'Song Name': songName,
      'Artist': artist,
      'Time of request': created,
      'Genre': genre,
      'Option': option,
    };

    if (tableNumber) {
      fields['Table Number'] = tableNumber;
    }

    base(tableId).create(
      [
        {
          fields: fields
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al guardar la solicitud de canción:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para obtener información del DJ
export const getDJInfo = async (formId) => {
  return new Promise((resolve, reject) => {
    base("Database DJ's")
      .select({
        filterByFormula: `{ID Form} = "${formId}"`,
        maxRecords: 1,
      })
      .firstPage((err, records) => {
        if (err) {
          console.error('Error al obtener la información del DJ:', err);
          reject(err);
        } else if (records.length > 0) {
          resolve(records[0].fields);
        } else {
          reject(new Error('No se encontró información para este DJ'));
        }
      });
  });
};

// Función para eliminar un registro de canción del DJ
export const deleteDJRecord = async (djViewId, recordId) => {
  return new Promise((resolve, reject) => {
    base(djViewId).destroy([recordId], function (err, deletedRecords) {
      if (err) {
        console.error('Error al eliminar el registro del DJ:', err);
        reject(err);
      } else {
        resolve(deletedRecords);
      }
    });
  });
};

// Función para obtener DJs por ubicación
export const getDJsByLocation = async (locationId) => {
  const records = await base("Database DJ's")
    .select({ filterByFormula: `{ID Home} = "${locationId}"` })
    .all();
  return records.map((record) => ({ id: record.id, fields: record.fields }));
};

// Función para actualizar un registro de DJ
export const updateDJRecord = async (recordId, fields) => {
  return new Promise((resolve, reject) => {
    base("Database DJ's").update(
      [
        {
          id: recordId,
          fields: fields,
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al actualizar el registro del DJ:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

/* ----------------------------------------
   Funciones relacionadas con Karaoke
---------------------------------------- */

// Función para obtener los registros de canciones del karaoke
export const getKaraokeRecords = async (karaokeViewId) => {
  return new Promise((resolve, reject) => {
    const recordsArray = [];
    base(karaokeViewId)
      .select({
        maxRecords: 100,
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            recordsArray.push({ id: record.id, fields: record.fields });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error('Error al obtener los registros del karaoke:', err);
            reject(err);
          } else {
            resolve(recordsArray);
          }
        }
      );
  });
};

// Función para crear una solicitud de canción para el karaoke (incluye el nombre del usuario)
export const createKaraokeSongRequest = async (
  formId,
  songTitle,
  artistName,
  genre,
  singerName
) => {
  return new Promise((resolve, reject) => {
    base(formId).create(
      [
        {
          fields: {
            'Song Name': songTitle,
            'Artist': artistName,
            'Genre': genre || '',
            'Singer Name': singerName,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al crear la solicitud de canción de karaoke:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para guardar una solicitud de canción para el karaoke (al mover canciones entre tablas)
export const saveKaraokeSongRequest = async (
  formId,
  songName,
  artistName,
  created,
  singerName,
  option = '',
  genre = ''
) => {
  return new Promise((resolve, reject) => {
    base(formId).create(
      [
        {
          fields: {
            'Song Name': songName,
            'Artist': artistName,
            'Time of request': created,
            'Singer Name': singerName,
            'Option': option,
            'Genre': genre,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al guardar la solicitud de canción de karaoke:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para obtener la información del lugar de karaoke
export const getKaraokePlaceInfo = async (formId) => {
  return new Promise((resolve, reject) => {
    base('Database Karaokes')
      .select({
        filterByFormula: `{ID Form} = "${formId}"`,
        maxRecords: 1,
      })
      .firstPage((err, records) => {
        if (err) {
          console.error('Error al obtener la información del lugar de karaoke:', err);
          reject(err);
        } else if (records.length > 0) {
          resolve(records[0].fields);
        } else {
          reject(new Error('No se encontró información para este lugar de karaoke'));
        }
      });
  });
};

// Función para eliminar un registro de canción del karaoke
export const deleteKaraokeRecord = async (karaokeViewId, recordId) => {
  return new Promise((resolve, reject) => {
    base(karaokeViewId).destroy([recordId], function (err, deletedRecords) {
      if (err) {
        console.error('Error al eliminar el registro del karaoke:', err);
        reject(err);
      } else {
        resolve(deletedRecords);
      }
    });
  });
};

/* Funciones para obtener información del DJ */

// Función para obtener la biografía del DJ
export const getDjBio = async (djName) => {
  return new Promise((resolve, reject) => {
    base('DJs Bio')
      .select({
        filterByFormula: `{username} = '${djName}'`,
        maxRecords: 1,
      })
      .firstPage((err, records) => {
        if (err) {
          console.error('Error al obtener la biografía del DJ:', err);
          reject(err);
        } else if (records.length > 0) {
          // Ahora devolvemos tanto el ID como los campos
          resolve({
            id: records[0].id,       // ID del registro
            fields: records[0].fields // Campos del DJ
          });
        } else {
          reject(new Error('No se encontró información para este DJ'));
        }
      });
  });
};

/* ----------------------------------------
   Otras funciones generales (si las hay)
---------------------------------------- */
// Función para crear reseña en Airtable
export const createReview = async (djId, comment, name) => {
  return new Promise((resolve, reject) => {
    base('Reseñas Djs').create(
      [
        {
          fields: {
            DJ: [djId],  // Enlazamos la reseña con el DJ
            Comentario: comment,
            Name:name
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al crear reseña:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para obtener las reseñas del DJ
export const getReviews = async (djId) => {
  return new Promise((resolve, reject) => {
    base('Reseñas Djs')
      .select({
        filterByFormula: `{DJ} = "${djId}"`,
        sort: [{ field: 'Fecha', direction: 'desc' }],
        maxRecords: 10,
      })
      .eachPage((records, fetchNextPage) => {
        const reviews = records.map((record) => ({
          id: record.id,
          fields: record.fields,
        }));
        resolve(reviews);
        fetchNextPage();
      }, (err) => {
        if (err) {
          console.error('Error al obtener reseñas:', err);
          reject(err);
        }
      });
  });
};

// Función para registrar pagos en Airtable
export const registerPaymentQR = async (paymentData) => {
  return new Promise((resolve, reject) => {
    // Suponiendo que paymentData.photo y paymentData.paymentFile contienen las URLs de Cloudinary
    base("Database DJ's").create(
      [
        {
          fields: {
            Name: paymentData.djName,
            InstagramLink: paymentData.instagramLink,
            Phone: paymentData.phone,
            Email: paymentData.email,
            Plan: paymentData.plan,
            Price: paymentData.price,
            'Payment Date': paymentData.paymentDate,
            Foto: [
              {
                url: paymentData.photo, // Foto URL de Cloudinary
              }
            ],
            'Payment File': [
              {
                url: paymentData.paymentFile, // Archivo URL de Cloudinary
              }
            ],
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al registrar el pago en Airtable:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};


export const registerInternationalPaymentQR = async (paymentData) => {
  return new Promise((resolve, reject) => {
    base("Database DJ's").create(
      [
        {
          fields: {
            Name: paymentData.djName,
            InstagramLink: paymentData.instagramLink,
            Phone: paymentData.phone,
            Email: paymentData.email,
            Plan: paymentData.plan,
            Price: paymentData.price,
            'Payment Date': paymentData.paymentDate,
            Foto: [
              {
                url: paymentData.photo, // Foto URL de Cloudinary
              },
            ],
            // No se incluye el campo 'Payment File'
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error("Error al registrar el pago internacional en Airtable:", err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

export const registerDJProfilePayment = async (paymentData) => {
  return new Promise((resolve, reject) => {
    // Convertir el array de locales a string separado por comas
    const localesString = Array.isArray(paymentData.locales) 
      ? paymentData.locales.join(', ')
      : paymentData.locales;

    base("DJs Bio").create(
      [
        {
          fields: {
            name: paymentData.djName,
            tagline: paymentData.tagline,
            Phone: paymentData.phone,
            Email: paymentData.email,
            bio: paymentData.bio,
            Experiencia: paymentData.experiencia,
            Lugar: paymentData.lugar,
            'Precio Min': paymentData.precioMin,
            'Precio Max': paymentData.precioMax,
            'Nombre Local': localesString, // Ahora enviamos un string
            musicUrl: paymentData.soundcloudUrl,
            youtubeUrl: paymentData.youtubeUrl,
            instagram: paymentData.instagramUrl,
            facebook: paymentData.facebookUrl,
            tiktok: paymentData.tiktokUrl,
            spotify: paymentData.spotifyUrl,
            photo: [
              {
                url: paymentData.profilePhoto
              }
            ],
            coverPhotoUrl: [
              {
                url: paymentData.coverPhoto || ''
              }
            ],
            gallery: paymentData.gallery.map(url => ({
              url: url
            })),
            Plan: paymentData.plan,
            Price: paymentData.price,
            'Payment Date': paymentData.paymentDate,
            'Payment File': paymentData.paymentFile ? [
              {
                url: paymentData.paymentFile
              }
            ] : [],
            username: paymentData.username
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al registrar el perfil DJ:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

export const registerInternationalDJProfilePayment = async (paymentData) => {
  return new Promise((resolve, reject) => {
    // Convertir el array de locales a string separado por comas
    const localesString = Array.isArray(paymentData.locales) 
      ? paymentData.locales.join(', ')
      : paymentData.locales;

    base("DJs Bio").create(
      [
        {
          fields: {
            name: paymentData.djName,
            tagline: paymentData.tagline,
            Phone: paymentData.phone,
            Email: paymentData.email,
            bio: paymentData.bio,
            Experiencia: paymentData.experiencia,
            Lugar: paymentData.lugar,
            'Precio Min': paymentData.precioMin,
            'Precio Max': paymentData.precioMax,
            'Nombre Local': localesString, // Ahora enviamos un string
            musicUrl: paymentData.soundcloudUrl,
            youtubeUrl: paymentData.youtubeUrl,
            instagram: paymentData.instagramUrl,
            facebook: paymentData.facebookUrl,
            tiktok: paymentData.tiktokUrl,
            spotify: paymentData.spotifyUrl,
            photo: [
              {
                url: paymentData.profilePhoto
              }
            ],
            coverPhotoUrl: [
              {
                url: paymentData.coverPhoto || ''
              }
            ],
            gallery: paymentData.gallery.map(url => ({
              url: url
            })),
            Plan: paymentData.plan,
            Price: paymentData.price,
            'Payment Date': paymentData.paymentDate,
            username: paymentData.username
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al registrar el perfil DJ internacional:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Si hay funciones compartidas o generales, se pueden colocar aquí.
