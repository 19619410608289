import React, { useState, memo } from 'react';
import { Box, Typography, Grid, Paper, Avatar, Chip, Tooltip, Button } from '@mui/material';
import { LocationOn, AttachMoney, Work, MoreHoriz } from '@mui/icons-material';
import { motion } from 'framer-motion';

// Animation Constants
const ANIMATION_CONFIG = {
  container: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  },
  item: {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  }
};

// Styles Constants
const STYLES = {
  card: {
    p: 3,
    textAlign: 'center',
    borderRadius: 2,
    minHeight: '400px'
  },
  avatar: {
    width: 100,
    height: 100,
    margin: '0 auto 20px'
  },
  infoIcon: {
    verticalAlign: 'middle',
    mr: 0.5
  },
  chip: {
    m: 0.5
  },
  viewMoreButton: {
    color: '#ffffff',
    borderColor: '#ffffff',
    borderRadius: '25px',
    padding: '10px 30px',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: '500',
    transition: 'all 0.3s ease',
    '&:hover': {
      borderColor: '#ffffff',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      transform: 'translateY(-2px)'
    }
  },
  contactButton: {
    mt: 2,
    color: '#ffffff',
    backgroundColor: '#115beb',
    borderRadius: 4
  }
};

// Componente para mostrar información con icono
const InfoRow = memo(({ icon, text }) => (
  <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mt: 2 }}>
    {React.cloneElement(icon, { sx: STYLES.infoIcon })}
    {text}
  </Typography>
));

// Componente para la sección de locales
const LocalesSection = memo(({ locales, hasMoreLocales }) => (
  <>
    <Typography variant="body2" color="textSecondary" gutterBottom>
      Locales:
    </Typography>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 1 }}>
      {locales.slice(0, 3).map((local, index) => (
        <Chip 
          key={index} 
          label={local}
          sx={{
            ...STYLES.chip,
            margin: '2px',
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
          }}
        />
      ))}
      {hasMoreLocales && (
        <Tooltip
          title={
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {locales.slice(3).map((local, index) => (
                <Chip 
                  key={index} 
                  label={local}
                  sx={{
                    ...STYLES.chip,
                    margin: '2px'
                  }}
                />
              ))}
            </Box>
          }
          arrow
          placement="top"
        >
          <Chip
            icon={<MoreHoriz />}
            label={`+${locales.length - 3}`}
            sx={STYLES.chip}
          />
        </Tooltip>
      )}
    </Box>
  </>
));

// Componente de tarjeta de DJ
const DJCard = memo(({ dj }) => {
  const { fields } = dj;
  
  const locales = fields['Nombre Local']
    ? fields['Nombre Local']
        .split(',')
        .map(local => local.trim())
        .filter(Boolean)
    : [];
  
  const hasMoreLocales = locales.length > 3;

  return (
    <Paper elevation={3} sx={STYLES.card}>
      <Avatar
        alt={fields['name']}
        src={fields['photo']?.[0]?.url}
        sx={STYLES.avatar}
      />
      <Typography variant="h6" gutterBottom>
        {fields['name']}
      </Typography>
      
      <InfoRow icon={<LocationOn fontSize="small" />} text={fields['Lugar']} />
      <InfoRow icon={<Work fontSize="small" />} text={`${fields['Experiencia']} años de experiencia`} />
      <InfoRow icon={<AttachMoney fontSize="small" />} text={`Precio: S/${fields['Precio Min']} - S/${fields['Precio Max']}`} />
      
      <LocalesSection locales={locales} hasMoreLocales={hasMoreLocales} />
      
      <Button
        component="a"
        href={`https://www.djponla.com/dj/${fields['username']}`}
        target="_blank"
        variant="outlined"
        sx={STYLES.contactButton}
      >
        Contactar
      </Button>
    </Paper>
  );
});

const DJsSection = ({ djs }) => {
  const [showAllDJs, setShowAllDJs] = useState(false);
  const djsWithPrice = djs.filter(dj => dj.fields['name']);
  const displayedDJs = showAllDJs ? djsWithPrice : djsWithPrice.slice(0, 3);

  return (
    <Box id="djs" sx={{ mb: 5, textAlign: 'center' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Los DJs que mejor la ponen 🔊
      </Typography>
      <Typography variant="body1" sx={{ color: '#A6A5A6', mb: 4, fontSize:'1.1rem' }}>
        Únete a nuestra comunidad de DJs profesionales y destaca tu talento
      </Typography>
      
      <Grid 
        container 
        spacing={4}
        component={motion.div}
        variants={ANIMATION_CONFIG.container}
        initial="hidden"
        animate="visible"
      >
        {displayedDJs.map((dj, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={4} 
            key={index}
            component={motion.div}
            variants={ANIMATION_CONFIG.item}
          >
            <DJCard dj={dj} />
          </Grid>
        ))}
      </Grid>

      {djsWithPrice.length > 3 && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button 
            variant="outlined"
            onClick={() => setShowAllDJs(!showAllDJs)}
            sx={STYLES.viewMoreButton}
          >
            {showAllDJs ? 'Mostrar menos' : 'Ver más DJs'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default memo(DJsSection);
