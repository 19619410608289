import React, { useState, useCallback, memo } from "react";
import { Box, Modal, Typography, IconButton, LinearProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import WorkIcon from "@mui/icons-material/Work";
import CollectionsIcon from "@mui/icons-material/Collections";
import ShareIcon from "@mui/icons-material/Share";
import PaymentIcon from "@mui/icons-material/Payment";

// Componentes
import PaymentStep from "../Payment/PaymentStepDJProfile";
import BasicInfoForm from "./forms/BasicInfoForm";
import MultimediaForm from "./forms/MultimediaForm";
import ProfessionalInfoForm from "./forms/ProfessionalInfoForm";
import GalleryLocalesForm from "./forms/GalleryLocalesForm";
import SocialMediaForm from "./forms/SocialMediaForm";
import PaymentStepDJProfile from "../Payment/PaymentStepDJProfile";

// Constantes
const INITIAL_FORM_DATA = {
  djName: "",
  tagline: "",
  phone: "",
  email: "",
  profilePhoto: null,
  profilePhotoPreview: null,
  coverPhoto: null,
  coverPhotoPreview: null,
  bio: "",
  experiencia: "",
  lugar: "",
  precioMin: "",
  precioMax: "",
  gallery: [],
  galleryPreviews: [],
  locales: "",
  soundcloudUrl: "",
  youtubeUrl: "",
  instagramUrl: "",
  facebookUrl: "",
  tiktokUrl: "",
  spotifyUrl: "",
};

const INITIAL_ERRORS = {
  djName: "",
  tagline: "",
  phone: "",
  email: "",
  profilePhoto: "",
  coverPhoto: "",
  bio: "",
  experiencia: "",
  lugar: "",
  precioMin: "",
  precioMax: "",
  locales: "",
  soundcloudUrl: "",
  youtubeUrl: "",
  instagramUrl: "",
  facebookUrl: "",
  tiktokUrl: "",
  spotifyUrl: "",
};

const STEPS = [
  { 
    label: "Básico",
    icon: <PersonIcon />,
    description: "Información personal"
  },
  { 
    label: "Multimedia",
    icon: <PhotoLibraryIcon />,
    description: "Fotos de perfil y portada"
  },
  { 
    label: "Profesional",
    icon: <WorkIcon />,
    description: "Experiencia y precios"
  },
  { 
    label: "Galería",
    icon: <CollectionsIcon />,
    description: "Fotos y locales"
  },
  { 
    label: "Social",
    icon: <ShareIcon />,
    description: "Redes sociales"
  },
  { 
    label: "Pago",
    icon: <PaymentIcon />,
    description: "Finalizar"
  }
];

const STYLES = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "90%" },
    maxWidth: 600,
    bgcolor: "#121212",
    borderRadius: 3,
    boxShadow: 24,
    p: { xs: 2, sm: 4 },
    maxHeight: { xs: "95vh", sm: "90vh" },
    overflowY: "auto",
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#333',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#666',
      borderRadius: '4px',
      '&:hover': {
        background: '#888',
      },
    },
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "#C2C2C2"
  }
};

// Componente StepperHeader memoizado
const StepperHeader = memo(({ step, steps }) => (
  <Box sx={{ mb: 3, px: { xs: 1, sm: 2 } }}>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      mb: 2,
      flexDirection: { xs: 'column', sm: 'row' },
      gap: { xs: 1, sm: 0 }
    }}>
      <Typography 
        variant="h6" 
        sx={{ 
          color: 'white', 
          flex: 1,
          fontSize: { xs: '1.1rem', sm: '1.25rem' }
        }}
      >
        {steps[step].label}
      </Typography>
      <Typography 
        variant="body2" 
        sx={{ 
          color: '#999',
          fontSize: { xs: '0.875rem', sm: '1rem' }
        }}
      >
        Paso {step + 1} de {steps.length}
      </Typography>
    </Box>
    
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      position: 'relative'
    }}>
      <LinearProgress
        variant="determinate"
        value={(step / (steps.length - 1)) * 100}
        sx={{
          width: '100%',
          height: 6,
          borderRadius: 3,
          backgroundColor: '#333',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#b70a20',
            borderRadius: 3,
          }
        }}
      />
      
      <Box sx={{
        position: 'absolute',
        left: `${(step / (steps.length - 1)) * 100}%`,
        transform: 'translateX(-50%)',
        backgroundColor: '#b70a20',
        borderRadius: '50%',
        p: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        {steps[step].icon}
      </Box>
    </Box>
    
    <Typography 
      variant="body2" 
      sx={{ 
        color: '#999', 
        mt: 2,
        textAlign: 'center' 
      }}
    >
      {steps[step].description}
    </Typography>
  </Box>
));

StepperHeader.displayName = 'StepperHeader';

const ProfileDJModal = ({ open, onClose, selectedPrice }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [errors, setErrors] = useState(INITIAL_ERRORS);

  // Handlers memoizados
  const handleBack = useCallback(() => {
    setStep(prev => prev - 1);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: "" }));
  }, []);

  const handleFileUpload = useCallback((e, fieldName) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData(prev => ({
        ...prev,
        [fieldName]: file,
        [`${fieldName}Preview`]: reader.result
      }));
      setErrors(prev => ({ ...prev, [fieldName]: "" }));
    };
    reader.readAsDataURL(file);
  }, []);

  const handleGalleryUpload = useCallback((e) => {
    const files = Array.from(e.target.files || []);
    const currentLength = formData.galleryPreviews?.length || 0;
    
    if (currentLength + files.length > 4) {
      setErrors(prev => ({
        ...prev,
        gallery: "Máximo 4 fotos permitidas"
      }));
      return;
    }

    const previews = files.map(file => ({
      file,
      type: file.type,
      url: URL.createObjectURL(file)
    }));

    setFormData(prev => ({
      ...prev,
      gallery: [...(prev.gallery || []), ...files],
      galleryPreviews: [...(prev.galleryPreviews || []), ...previews]
    }));
  }, [formData.galleryPreviews?.length]);

  const handleRemoveImage = useCallback((index) => {
    setFormData(prev => {
      const newGallery = [...prev.gallery];
      const newPreviews = [...prev.galleryPreviews];
      
      URL.revokeObjectURL(newPreviews[index].url);
      
      newGallery.splice(index, 1);
      newPreviews.splice(index, 1);
      
      return {
        ...prev,
        gallery: newGallery,
        galleryPreviews: newPreviews
      };
    });
  }, []);

  const validateStep = useCallback((currentStep, data) => {
    const validations = {
      0: () => {
        const errors = {};
        if (!data.djName) errors.djName = "El nombre es obligatorio";
        if (!data.phone) errors.phone = "El teléfono es obligatorio";
        if (!data.email) errors.email = "El email es obligatorio";
        return errors;
      },
      1: () => {
        const errors = {};
        if (!data.profilePhoto) errors.profilePhoto = "La foto de perfil es obligatoria";
        return errors;
      },
      2: () => {
        const errors = {};
        if (!data.bio) errors.bio = "La biografía es obligatoria";
        if (!data.experiencia) errors.experiencia = "La experiencia es obligatoria";
        if (!data.lugar) errors.lugar = "El lugar es obligatorio";
        if (!data.precioMin || !data.precioMax) {
          errors.precioMin = "Los precios son obligatorios";
        }
        return errors;
      },
      3: () => {
        const errors = {};
        if (!data.gallery?.length) errors.gallery = "Debes subir al menos una foto";
        if (!data.locales) errors.locales = "Debes agregar al menos un local";
        return errors;
      },
      4: () => {
        const errors = {};
        if (!data.instagramUrl) errors.instagramUrl = "El Instagram es obligatorio";
        return errors;
      }
    };

    return validations[currentStep]?.() || {};
  }, []);

  const handleNext = useCallback(() => {
    const newErrors = validateStep(step, formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setStep(prev => prev + 1);
    }
  }, [step, formData, validateStep]);

  const renderStepContent = useCallback((stepIndex) => {
    const stepComponents = {
      0: <BasicInfoForm
           formData={formData}
           errors={errors}
           handleChange={handleChange}
           handleNext={handleNext}
         />,
      1: <MultimediaForm
           formData={formData}
           errors={errors}
           handleFileUpload={handleFileUpload}
           handleNext={handleNext}
           handleBack={handleBack}
         />,
      2: <ProfessionalInfoForm
           formData={formData}
           errors={errors}
           handleChange={handleChange}
           handleNext={handleNext}
           handleBack={handleBack}
         />,
      3: <GalleryLocalesForm
           formData={formData}
           errors={errors}
           handleGalleryUpload={handleGalleryUpload}
           handleChange={handleChange}
           handleNext={handleNext}
           handleBack={handleBack}
           handleRemoveImage={handleRemoveImage}
         />,
      4: <SocialMediaForm
           formData={formData}
           errors={errors}
           handleChange={handleChange}
           handleNext={handleNext}
           handleBack={handleBack}
         />,
      5: <PaymentStepDJProfile
           onClose={onClose}
           selectedPrice={selectedPrice}
           formData={formData}
         />
    };

    return stepComponents[stepIndex] || null;
  }, [
    formData,
    errors,
    handleChange,
    handleNext,
    handleBack,
    handleFileUpload,
    handleGalleryUpload,
    handleRemoveImage,
    onClose,
    selectedPrice
  ]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={STYLES.modal}>
        <IconButton 
          onClick={onClose} 
          sx={STYLES.closeButton}
        >
          <CloseIcon />
        </IconButton>

        <StepperHeader step={step} steps={STEPS} />

        {renderStepContent(step)}
      </Box>
    </Modal>
  );
};

export default memo(ProfileDJModal); 