import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  getKaraokeRecords,
  deleteKaraokeRecord,
  saveKaraokeSongRequest,
  getKaraokePlaceInfo,
} from '../api/airtable';
import {
  Container,
  Grid,
  Typography,
  Box,
  useMediaQuery,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import KaraokeSongCard from '../components/Karaoke/KaraokeSongCard';
import LogoExtendido from '../assets/Logo DJ Ponla extendido (sin fondo).png';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { motion } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';

const KaraokeView = () => {
  const { karaokeId } = useParams();
  const [songs, setSongs] = useState([]);
  const [loadingSongs, setLoadingSongs] = useState({});
  const [error, setError] = useState(null);
  const [placeInfo, setPlaceInfo] = useState({});
  const [currentMessage, setCurrentMessage] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const messages = [
    '🎤 ¡Prepárate para cantar y brillar en el escenario! 🎶',
    '⬅️ Desliza a la izquierda las canciones que no se cantarán 🎵',
    '😎 Aún no hay canciones en la lista, ¡pero pronto las habrá!',
    '💪 ¡Haz que esta noche de karaoke sea inolvidable!',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => (prevMessage + 1) % messages.length);
    }, 5000); // Cambia cada 5 segundos

    return () => clearInterval(interval);
  }, [messages.length]);

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        const records = await getKaraokeRecords(karaokeId);
        setSongs(
          records.sort(
            (a, b) => new Date(a.fields['Created']) - new Date(b.fields['Created'])
          )
        );
      } catch (error) {
        console.error('Error al cargar las canciones:', error);
        setError(
          'Hubo un error al cargar las canciones. Por favor, inténtalo de nuevo.'
        );
      }
    };

    fetchSongs();

    const interval = setInterval(fetchSongs, 8000); // Actualiza cada 8 segundos
    return () => clearInterval(interval);
  }, [karaokeId]);

  useEffect(() => {
    const fetchPlaceInfo = async () => {
      try {
        const info = await getKaraokePlaceInfo(karaokeId);
        setPlaceInfo(info);
      } catch (error) {
        console.error('Error al obtener información del lugar de karaoke:', error);
      }
    };
    fetchPlaceInfo();
  }, [karaokeId]);

  const handleDelete = async (recordId, option = '') => {
    setLoadingSongs((prevState) => ({ ...prevState, [recordId]: true }));

    try {
      const songToDelete = songs.find((song) => song.id === recordId);
      const newTableId = karaokeId.replace(/^f|m$/g, '');
      await saveKaraokeSongRequest(
        newTableId,
        songToDelete.fields['Song Name'],
        songToDelete.fields['Artist'],
        songToDelete.fields['Created'],
        songToDelete.fields['Singer Name'],
        option,
        songToDelete.fields['Genre']
      );
      await deleteKaraokeRecord(karaokeId, recordId);
      setSongs((prevSongs) => prevSongs.filter((song) => song.id !== recordId));
    } catch (error) {
      console.error('Error al eliminar la canción:', error);
    } finally {
      setLoadingSongs((prevState) => ({ ...prevState, [recordId]: false }));
    }
  };

  // Función para determinar si una canción es nueva (agregada en los últimos 5 minutos)
  const isNewSong = (createdDate) => {
    const now = new Date();
    const songDate = new Date(createdDate);
    const diffInMinutes = (now - songDate) / (1000 * 60);
    return diffInMinutes <= 5; // Puedes ajustar el tiempo según tus necesidades
  };

  const handleFilter = useCallback(() => {
    setIsFiltered((prev) => !prev);
  }, []);

  const filteredSongs = useMemo(() => {
    return isFiltered 
      ? [...songs].sort((a, b) => a.fields['Artist'].localeCompare(b.fields['Artist']))
      : songs;
  }, [songs, isFiltered]);

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#121212', color: '#fff', py: 2 }}>
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
          <img
            src={LogoExtendido}
            alt="Logo Karaoke"
            style={{ width: '60%', height: 'auto', borderRadius: 10 }}
          />
        </Box>

        {/* Mensaje de Bienvenida Mejorado */}
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: isMobile ? '1.5rem' : '2rem',
              fontWeight: 'bold',
              mb: 1,
            }}
          >
            ¡Bienvenido, {placeInfo.Name}!
          </Typography>
        </Box>

        {/* Título y contador de canciones */}
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem', mr: 1 }}
          >
            La gente quiere cantar
          </Typography>
        </Box>

        {/* Botón de filtro y contador de canciones */}
        <Box
          sx={{
            textAlign: 'center',
            mb: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<FilterListIcon />}
            sx={{ borderRadius: 5, fontSize: isMobile ? '0.8rem' : '1rem' }}
            onClick={handleFilter}
          >
            {isFiltered ? 'Mostrar todo' : 'Filtrar por Artista'}
          </Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <LibraryMusicIcon sx={{ fontSize: isMobile ? '1.5rem' : '2rem' }} />
            <Typography variant="h6" sx={{ fontSize: isMobile ? '1rem' : '1.2rem' }}>
              {filteredSongs.length}
            </Typography>
          </Box>
        </Box>

        {/* Mensaje dinámico cuando no hay canciones */}
        {filteredSongs.length === 0 && (
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              color: '#fff',
              marginTop: 4,
            }}
          >
            <motion.div
              key={currentMessage}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{ marginTop: '1rem' }}
            >
              <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                {messages[currentMessage]}
              </Typography>
            </motion.div>
          </Box>
        )}

        {/* Lista de canciones actualizada */}
        <Grid container spacing={2}>
          {filteredSongs.map((song, index) => (
            <Grid item xs={12} sm={6} md={6} key={song.id}>
              <KaraokeSongCard
                song={song}
                handleDelete={handleDelete}
                loading={loadingSongs[song.id]}
                isNew={isNewSong(song.fields['Created'])}
              />
            </Grid>
          ))}
        </Grid>

        {error && (
          <Box textAlign="center" mt={4}>
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default KaraokeView;
