import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import LogoExtendido from '../../assets/Logo DJ Ponla extendido (sin fondo).png';
import { Link } from 'react-router-dom';

const PreviewDJ = ({ djName, djPhoto, creatorName }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#121212", // Fondo oscuro
        color: "#fff",
        padding: "20px",
        textAlign: "center",
        borderRadius: "10px",
        maxWidth: "350px", // Hacer más pequeño el ancho
        margin: "0 auto", // Centrar el contenido
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Sombra alrededor
        border: "2px solid #fff", // Borde blanco
      }}
    >
      {/* Imagen del DJ centrada con borde */}
      <Avatar
        src={djPhoto}
        alt={djName}
        sx={{
          width: "220px", // Reducir el tamaño de la imagen
          height: "220px", // Mantener proporción cuadrada
          borderRadius: "10px",
          marginBottom: "15px", // Reducir el margen inferior
          marginLeft: "auto", // Centrar la imagen
          marginRight: "auto", // Centrar la imagen
        }}
        variant="square"
      />

      {/* Nombre del DJ */}
      <Typography variant="h6" gutterBottom sx={{ fontSize: "18px" }}>
        Tocando {djName}
      </Typography>

      {/* Pregunta */}
      <Typography variant="body2" gutterBottom>
        ¿Qué canción quieres escuchar?
      </Typography>

      {/* Campo de texto sin funcionalidad */}
      <Typography variant="body2" sx={{ backgroundColor: "transparent", padding: "10px", borderRadius: "5px", marginBottom: "15px", border:'1px solid #fff' }}>
        Nombre de la canción
      </Typography>

      {/* Botón de búsqueda visual, pero no funcional */}
      <Box
        sx={{
          backgroundColor: '#54A772',
          color: "#fff",
          padding: "5px",
          borderRadius: "5px",
          marginBottom: "15px",
          cursor: "not-allowed", // Indicar que no es funcional
        }}
      >
        Buscar
      </Box>

      {/* Pie de página */}
      <Typography
        variant="body2"
        sx={{ marginTop: "15px", fontSize: "14px", color: "#aaa" }}
      >
        Creado por:
      </Typography>
      <Link to="/">
    <img src={LogoExtendido} alt='Logo DJPonla' style={{ width: '50%', height: '50%', borderRadius: 10 }} />
    </Link>
    </Box>
  );
};

export default PreviewDJ;
