import React from 'react';
import { Box, Button, CircularProgress, Fade, Typography } from '@mui/material';
import { UploadFile } from '@mui/icons-material';

const FileUpload = ({ handleFileUpload, uploading, fileUploaded, fileName }) => (
  <>
    <Typography variant="body1" sx={{ color: '#333', fontWeight: 'bold', mb: 2 }}>
      Adjunta una captura de tu comprobante:
    </Typography>

    <Box
      sx={{
        border: '2px dashed #3f51b5',
        borderRadius: 2,
        p: 2,
        mb: 2,
        backgroundColor: '#e3f2fd',
        textAlign: 'center',
        transition: 'all 0.3s',
        '&:hover': { backgroundColor: '#bbdefb' },
      }}
    >
      <UploadFile sx={{ fontSize: 40, color: '#3f51b5', mb: 1 }} />
      <Button
        variant="outlined"
        component="label"
        fullWidth
        sx={{
          mb: 2,
          borderColor: uploading ? '#ff9800' : '#3f51b5',
          color: uploading ? '#ff9800' : '#3f51b5',
          fontWeight: 'bold',
        }}
      >
        {uploading ? 'Subiendo...' : 'Subir Comprobante'}
        <input type="file" accept="image/*" hidden onChange={handleFileUpload} />
      </Button>
    </Box>

    {uploading && (
      <Box sx={{ my: 2 }}>
        <CircularProgress size={30} sx={{ color: '#ff9800' }} />
      </Box>
    )}

    {fileUploaded && (
      <Fade in={fileUploaded}>
        <Box sx={{ textAlign: 'center', my: 2 }}>
          <Typography variant="body2" sx={{ color: 'green', fontWeight: 'bold' }}>
            ¡Comprobante subido con éxito!
          </Typography>
          <Typography variant="body2" sx={{ color: '#555' }}>
            Archivo: {fileName}
          </Typography>
        </Box>
      </Fade>
    )}
  </>
);

export default FileUpload; 