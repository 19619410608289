import React, { useState } from "react";
import { Box, Typography, IconButton, useTheme, Grid } from "@mui/material";
import { ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import Navbar from "../components/Navbar";
import DJModal from "../components/QRDJProduct/QRDJModal";
import StepperComponent from "../components/StepperComponent";
import StepCard from "../components/StepCard";
import BillingToggle from "../components/BillingToggle";
import PlanCard from "../components/PlanCard";
import EscaneoQR from "../assets/EscaneoQR.jpeg";
import CancionPedida from "../assets/Cancion pedida.png";
import VistadeCanciones from "../assets/vistadecanciones.png";
import TomaElControl from "../assets/TomaElControl.jpeg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import UpdateIcon from "@mui/icons-material/Update";
import InfoIcon from "@mui/icons-material/Info";

const QRDJProduct = () => {
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [activeStep, setActiveStep] = useState(0);
  const [openDJModal, setOpenDJModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const plans = {
    monthly: { price: "S/.35", title: "Plan Mensual", subtitle: "Ideal para empezar y explorar." },
    yearly: { price: "S/.350", title: "Plan Anual", subtitle: "Ahorra S/.70 con este plan exclusivo." },
  };

  const features = [
    { text: "Vistas personalizadas de sugerencias de canciones.", icon: <CheckCircleIcon /> },
    { text: "Un QR personalizado único para tu evento.", icon: <QrCodeIcon /> },
    { text: "Datos detallados sobre las peticiones de canciones.", icon: <DataUsageIcon /> },
    { text: "Acceso a próximas actualizaciones.", icon: <UpdateIcon /> },
  ];

  const steps = [
    {
      title: "Escanea el código QR",
      description: "Tu público puede escanear el código QR único desde cualquier dispositivo.",
      image: EscaneoQR,
    },
    {
      title: "Envía tu canción",
      description: "El público puede solicitar canciones personalizadas directamente desde sus dispositivos.",
      image: CancionPedida,
    },
    {
      title: "Recibe las solicitudes",
      description: "El DJ recibe las solicitudes en tiempo real y puede decidir cuáles poner.",
      image: VistadeCanciones,
    },
    {
      title: "Toma el control",
      description: "Como DJ, tú decides qué canciones reproducir, manteniendo el ambiente ideal.",
      image: TomaElControl,
    },
  ];

  const SLIDER_WIDTH = "800px";
  const IMAGE_HEIGHT = "400px";

  const handleToggle = (_, value) => {
    if (value !== null) setBillingCycle(value);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => (prevStep + 1) % steps.length);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep - 1 + steps.length) % steps.length);
  };

  const handleOpenModal = () => {
    const selectedPlanInfo = `${plans[billingCycle].title} - ${plans[billingCycle].price}`;
    setSelectedPlan(selectedPlanInfo);
    setOpenDJModal(true);
  };

  const theme = useTheme();

  return (
    <>
      <Navbar />
      <Box
        sx={{
          textAlign: "center",
          padding: 4,
          backgroundColor: theme.palette.primary.main,
          color: "white",
          minHeight: "100vh",
          mt: { xs: 2, sm: 5 },
        }}
      >
        <Box
          sx={{
            mt: 4,
            position: "relative",
            maxWidth: { xs: "90%", md: "60%" },
            margin: "auto",
            opacity: 0,
            animation: "fadeIn 0.8s ease-out forwards",
            "@keyframes fadeIn": {
              from: { opacity: 0, transform: "translateY(20px)" },
              to: { opacity: 1, transform: "translateY(0)" }
            }
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "#ffffff",
            mb: 2,
            mt: { xs: 10, sm: 6 }
            }}
          >
            Sistema QR para DJs
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 4,
              color: "rgba(255,255,255,0.9)",
              maxWidth: "600px",
              margin: "0 auto",
              lineHeight: 1.5
            }}
          >
            La forma más inteligente de conectar con tu público
          </Typography>
        </Box>

        <BillingToggle 
          billingCycle={billingCycle} 
          handleToggle={handleToggle}
          sx={{
            '.MuiToggleButtonGroup-root': {
              background: 'rgba(255,255,255,0.05)',
              backdropFilter: 'blur(10px)',
              borderRadius: '15px',
              padding: '4px',
              border: '1px solid rgba(255,255,255,0.1)',
            },
            '.MuiToggleButton-root': {
              border: 'none',
              borderRadius: '12px !important',
              color: 'rgba(255,255,255,0.7)',
              padding: '8px 24px',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)',
              },
              '&.Mui-selected': {
                backgroundColor: '#b70a20',
                color: '#fff',
                fontWeight: 'bold',
                boxShadow: '0 4px 12px rgba(183, 10, 32, 0.3)',
                '&:hover': {
                  backgroundColor: '#d32f2f',
                }
              }
            }
          }}
        />
        <PlanCard 
          plan={plans[billingCycle]} 
          features={features} 
          handleOpenModal={handleOpenModal} 
          color="#b70a20" 
          sx={{
            transform: "scale(1.05)",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.08)",
            },
            boxShadow: "0 8px 32px rgba(0,0,0,0.2)"
          }}
        />
        <Box sx={{ 
          position: 'relative', 
          my: 4,
          maxWidth: SLIDER_WIDTH,
          margin: "0 auto",
          px: { xs: 3, sm: 6 },
          mt: { xs: 4, sm: 6 }
        }}>
          <StepperComponent steps={steps} activeStep={activeStep} />
          <StepCard 
            step={steps[activeStep]} 
            sx={{
              '& img': {
                width: '100%',
                height: IMAGE_HEIGHT,
                objectFit: 'cover',
                borderRadius: '12px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.2)'
              },
              '.MuiCard-root': {
                background: 'rgba(255,255,255,0.05)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '16px',
                maxWidth: '100%',
                mx: 'auto'
              }
            }}
          />
          
          <IconButton
            onClick={handleBack}
            sx={{
              position: "absolute",
              left: { xs: 0, sm: 0 },
              top: "50%",
              transform: "translateY(-50%)",
              color: "white",
              zIndex: 1,
              backgroundColor: "rgba(0,0,0,0.5)",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
              width: 40,
              height: 40,
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: "50%",
              transform: "translateY(-50%)",
              color: "white",
              zIndex: 1,
              backgroundColor: "rgba(0,0,0,0.5)",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
              width: 40,
              height: 40,
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <DJModal
          open={openDJModal}
          onClose={() => setOpenDJModal(false)}
          selectedPrice={plans[billingCycle].price}
        />

        <Typography 
          variant="body2" 
          sx={{ 
            marginTop: 3, 
            color: "rgba(255,255,255,0.7)", 
            fontStyle: "italic",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1
          }}
        >
          <InfoIcon sx={{ fontSize: 16 }} />
          Cancela en cualquier momento. Sin compromisos.
        </Typography>
      </Box>
    </>
  );
};

export default QRDJProduct; 