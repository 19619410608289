import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import SoundcloudIcon from '@mui/icons-material/Audiotrack';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const SocialMediaForm = ({ formData, errors, handleChange, handleNext, handleBack }) => {
  const socialFields = [
    {
      name: 'soundcloudUrl',
      label: 'Link de Soundcloud',
      icon: <SoundcloudIcon sx={{ color: '#ff7700' }} />,
    },
    {
      name: 'youtubeUrl',
      label: 'Link de YouTube',
      icon: <YouTubeIcon sx={{ color: '#ff0000' }} />,
    },
    {
      name: 'instagramUrl',
      label: 'Link de Instagram',
      icon: <InstagramIcon sx={{ color: '#e4405f' }} />,
    },
    {
      name: 'facebookUrl',
      label: 'Link de Facebook',
      icon: <FacebookIcon sx={{ color: '#1877f2' }} />,
    },
    {
      name: 'tiktokUrl',
      label: 'Link de TikTok',
      icon: <MusicNoteIcon sx={{ color: '#000000' }} />,
    },
    {
      name: 'spotifyUrl',
      label: 'Link de Spotify',
      icon: <MusicNoteIcon sx={{ color: '#1db954' }} />,
    },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Redes Sociales
      </Typography>

      {socialFields.map((field) => (
        <Box key={field.name} sx={{ mb: 2, display: 'flex', alignItems: 'flex-start' }}>
          <Box sx={{ mr: 1, mt: 2 }}>{field.icon}</Box>
          <TextField
            fullWidth
            label={field.label}
            name={field.name}
            value={formData[field.name]}
            onChange={handleChange}
            error={!!errors[field.name]}
            helperText={errors[field.name]}
          />
        </Box>
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          sx={{ color: '#ffffff', borderColor: '#ffffff' }}
        >
          Atrás
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ backgroundColor: "#4caf50" }}
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};

export default SocialMediaForm; 