import React, { useState, useEffect, useMemo } from 'react';
import { getDJRecords, getDJInfo } from '../api/airtable';
import {
  Container,
  Typography,
  Box,
  useMediaQuery,
  Tab,
  Tabs,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import GeneralStats from '../components/analytics/GeneralStats';
import ArtistAnalysis from '../components/analytics/ArtistAnalysis';
import GenreAnalysis from '../components/analytics/GenreAnalysis';
import TimeAnalysis from '../components/analytics/TimeAnalysis';
import PlaybackStatus from '../components/analytics/PlaybackStatus';
import SongList from '../components/analytics/SongList';

const DataAnalyticsView = () => {
  const { djId } = useParams();
  const [songs, setSongs] = useState([]);
  const [djInfo, setDjInfo] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await getDJRecords(djId);
        setSongs(records);
        // Modificamos el ID para obtener la información del DJ
        const formattedDjId = `f${djId}m`;
        const info = await getDJInfo(formattedDjId);
        setDjInfo(info);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [djId]);

  // Estadísticas generales
  const statistics = useMemo(() => {
    const totalSongs = songs.length;
    const uniqueArtists = new Set(songs.map(song => song.fields['Artist'])).size;
    const uniqueSongs = new Set(songs.map(song => song.fields['Song Name'])).size;
    const genres = songs.map(song => song.fields['Genre']).filter(Boolean);
    const uniqueGenres = new Set(genres).size;
    
    // Análisis de tiempo usando Time of request
    const timeRequests = songs.reduce((acc, song) => {
      const timeStr = song.fields['Time of request'];
      if (timeStr) {
        const date = new Date(timeStr);
        const hour = date.getHours();
        acc[hour] = (acc[hour] || 0) + 1;
      }
      return acc;
    }, {});

    // Encontrar la hora con más solicitudes
    const peakHourData = Object.entries(timeRequests)
      .sort(([, a], [, b]) => b - a)[0];
    
    const peakHour = peakHourData ? 
      `${peakHourData[0]}:00 (${peakHourData[1]} solicitudes)` : 
      'N/A';

    // Calcular canciones por día
    const dateCount = new Set(
      songs.map(song => 
        song.fields['Time of request'] ? 
        new Date(song.fields['Time of request']).toLocaleDateString() : 
        null
      ).filter(Boolean)
    ).size;

    const songsPerDay = dateCount > 0 ? 
      (totalSongs / dateCount).toFixed(1) : 
      'N/A';

    // Análisis de opciones de reproducción
    const playedCount = songs.filter(song => 
      song.fields['Option'] === 'Se puso'
    ).length;
    
    const notPlayedCount = songs.filter(song => 
      song.fields['Option'] === 'No la quiero poner'
    ).length;

    const playedPercentage = totalSongs > 0 ? 
      ((playedCount / totalSongs) * 100).toFixed(1) : 
      0;

    return {
      totalRequests: totalSongs,
      uniqueArtists,
      uniqueSongs,
      uniqueGenres,
      songsPerDay,
      peakHour,
      playedSongs: playedCount,
      playedPercentage: `${playedPercentage}%`
    };
  }, [songs]);

  // Mejorar el análisis de artistas
  const artistAnalysis = useMemo(() => {
    const artistStats = songs.reduce((acc, song) => {
      const artist = song.fields['Artist'];
      if (!acc[artist]) {
        acc[artist] = {
          totalRequests: 0,
          played: 0,
          notPlayed: 0,
          songs: new Set(),
        };
      }
      acc[artist].totalRequests++;
      acc[artist].songs.add(song.fields['Song Name']);
      
      if (song.fields['Option'] === 'Se puso') {
        acc[artist].played++;
      } else if (song.fields['Option'] === 'No la quiero poner') {
        acc[artist].notPlayed++;
      }
      return acc;
    }, {});

    return Object.entries(artistStats)
      .map(([artist, stats]) => ({
        name: artist,
        requests: stats.totalRequests,
        played: stats.played,
        notPlayed: stats.notPlayed,
        uniqueSongs: stats.songs.size,
        playRate: ((stats.played / stats.totalRequests) * 100).toFixed(1)
      }))
      .sort((a, b) => b.requests - a.requests)
      .slice(0, 10);
  }, [songs]);

  // Modificar el análisis de géneros para mostrar top 5 y agrupar el resto
  const genreAnalysis = useMemo(() => {
    const genreStats = songs.reduce((acc, song) => {
      const genre = song.fields['Genre'] || 'Sin género';
      if (!acc[genre]) {
        acc[genre] = {
          total: 0,
          played: 0,
          notPlayed: 0,
          artists: new Set(),
          songs: new Set()
        };
      }
      acc[genre].total++;
      acc[genre].artists.add(song.fields['Artist']);
      acc[genre].songs.add(song.fields['Song Name']);
      
      if (song.fields['Option'] === 'Se puso') {
        acc[genre].played++;
      } else if (song.fields['Option'] === 'No la quiero poner') {
        acc[genre].notPlayed++;
      }
      return acc;
    }, {});

    const sortedGenres = Object.entries(genreStats)
      .map(([genre, stats]) => ({
        name: genre,
        total: stats.total,
        played: stats.played,
        notPlayed: stats.notPlayed,
        artistCount: stats.artists.size,
        songCount: stats.songs.size,
        playRate: ((stats.played / stats.total) * 100).toFixed(1)
      }))
      .sort((a, b) => b.total - a.total);

    // Tomar los top 5 géneros
    const top5 = sortedGenres.slice(0, 5);
    
    // Agrupar el resto en "Otros"
    if (sortedGenres.length > 5) {
      const others = sortedGenres.slice(5).reduce(
        (acc, genre) => ({
          name: 'Otros Géneros',
          total: acc.total + genre.total,
          played: acc.played + genre.played,
          notPlayed: acc.notPlayed + genre.notPlayed,
          artistCount: acc.artistCount + genre.artistCount,
          songCount: acc.songCount + genre.songCount,
          playRate: ((acc.played + genre.played) / (acc.total + genre.total) * 100).toFixed(1)
        }),
        { name: 'Otros Géneros', total: 0, played: 0, notPlayed: 0, artistCount: 0, songCount: 0, playRate: 0 }
      );
      
      top5.push(others);
    }

    return top5;
  }, [songs]);

  // Análisis temporal mejorado
  const timeAnalysis = useMemo(() => {
    // Agrupar por períodos del día
    const periods = {
      madrugada: { name: 'Madrugada', hours: [0, 1, 2, 3, 4, 5], total: 0, played: 0 },
      mañana: { name: 'Mañana', hours: [6, 7, 8, 9, 10, 11], total: 0, played: 0 },
      tarde: { name: 'Tarde', hours: [12, 13, 14, 15, 16, 17], total: 0, played: 0 },
      noche: { name: 'Noche', hours: [18, 19, 20, 21, 22, 23], total: 0, played: 0 }
    };

    // Datos por hora para el heatmap
    const hourlyData = Array.from({ length: 24 }, (_, hour) => ({
      hour,
      timeLabel: `${hour % 12 || 12}${hour < 12 ? 'AM' : 'PM'}`,
      value: 0,
      played: 0,
      notPlayed: 0
    }));

    songs.forEach(song => {
      if (song.fields['Time of request']) {
        const hour = new Date(song.fields['Time of request']).getHours();
        hourlyData[hour].value++;
        
        if (song.fields['Option'] === 'Se puso') {
          hourlyData[hour].played++;
        } else if (song.fields['Option'] === 'No la quiero poner') {
          hourlyData[hour].notPlayed++;
        }

        // Actualizar períodos
        Object.values(periods).forEach(period => {
          if (period.hours.includes(hour)) {
            period.total++;
            if (song.fields['Option'] === 'Se puso') {
              period.played++;
            }
          }
        });
      }
    });

    return {
      hourlyData,
      periods: Object.values(periods).map(period => ({
        ...period,
        playRate: period.total > 0 ? (period.played / period.total * 100).toFixed(1) : 0
      }))
    };
  }, [songs]);

  // Función para formatear las opciones
  const formatOption = (option) => {
    switch(option) {
      case 'Se puso':
        return '✅ Se reprodujo';
      case 'No la quiero poner':
        return '❌ No reproducida';
      default:
        return '⏳ Pendiente';
    }
  };

  // Datos formateados para la tabla
  const tableData = useMemo(() => {
    return songs.map(song => ({
      artist: song.fields['Artist'] || 'Sin artista',
      song: song.fields['Song Name'] || 'Sin título',
      genre: song.fields['Genre'] || 'Sin género',
      rawOption: song.fields['Option'],
      option: song.fields['Option'] === 'Se puso' ? '✅ Reproducida' :
              song.fields['Option'] === 'No la quiero poner' ? '❌ No Reproducida' : 
              '⏳ Pendiente'
    }));
  }, [songs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Actualizar el renderizado de las opciones en el gráfico
  const playOptionsData = useMemo(() => {
    const optionCount = songs.reduce((acc, song) => {
      const option = song.fields['Option'] || 'Pendiente';
      acc[option] = (acc[option] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(optionCount).map(([option, count]) => ({
      name: formatOption(option),
      value: count,
      rawOption: option
    }));
  }, [songs]);

  // Función para determinar el color basado en la intensidad
  const getIntensityColor = (value, maxValue) => {
    const intensity = value / maxValue;
    if (intensity <= 0.3) return '#FFB4B4'; // Baja - Rosa suave
    if (intensity <= 0.7) return '#FF7676'; // Media - Rosa medio
    return '#FF4646'; // Alta - Rosa fuerte
  };

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      background: 'linear-gradient(135deg, #1e1e1e 0%, #2d2d2d 100%)',
      color: '#fff', 
      py: 4 
    }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h3" 
          sx={{ 
            mb: 4, 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: { xs: '1.8rem', sm: '3rem' },
            background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          Dashboard Analítico de {djInfo.Name}
        </Typography>

        <Box sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          position: 'relative',
          '&::after': {
            content: '""',
            display: { xs: 'block', md: 'none' },
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            width: '24px',
            background: 'linear-gradient(to right, transparent, #1e1e1e)',
            pointerEvents: 'none'
          }
        }}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
            allowScrollButtonsMobile
            centered={!isMobile}
            sx={{
              mb: 4,
              '& .MuiTab-root': {
                color: '#fff',
                fontSize: { xs: '0.8rem', sm: '1rem' },
                minWidth: { xs: 'auto', sm: 160 },
                '&.Mui-selected': {
                  color: '#4ECDC4'
                }
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#4ECDC4'
              },
              '& .MuiTabs-scrollButtons': {
                color: '#fff',
                '&.Mui-disabled': {
                  opacity: 0.3
                }
              }
            }}
          >
            <Tab label="General" />
            <Tab label="Artistas" />
            <Tab label="Géneros" />
            <Tab label="Temporal" />
            <Tab label="Estado" />
            <Tab label="Listado" />
          </Tabs>
        </Box>

        {activeTab === 0 && <GeneralStats statistics={statistics} />}
        {activeTab === 1 && <ArtistAnalysis artistAnalysis={artistAnalysis} />}
        {activeTab === 2 && <GenreAnalysis genreAnalysis={genreAnalysis} />}
        {activeTab === 3 && <TimeAnalysis timeAnalysis={timeAnalysis} />}
        {activeTab === 4 && (
          <PlaybackStatus 
            playOptionsData={playOptionsData}
            statistics={statistics}
          />
        )}
        {activeTab === 5 && (
          <SongList 
            tableData={tableData}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Container>
    </Box>
  );
};

export default DataAnalyticsView; 