import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  TextField, Button, List, ListItem, ListItemText, Paper, Container, Typography, CircularProgress, ListItemAvatar, Avatar, Divider
} from '@mui/material';
import { searchSongs, getGenreFromDeezer } from '../api/deezer';
import { createKaraokeSongRequest, getKaraokePlaceInfo } from '../api/airtable';
import { useParams } from 'react-router-dom';
import KaraokePlaceInfoSection from '../components/Karaoke/KaraokePlaceInfoSection';
import KaraokeSelectedSongSection from '../components/Karaoke/KaraokeSelectedSongSection';
import FooterLogo from '../components/FooterLogo';
import KaraokeSongRequestModal from '../components/Karaoke/KaraokeSongRequestModal';

const KaraokeSongRequestForm = () => {
  const { formId } = useParams();
  const [query, setQuery] = useState('');
  const [singerName, setSingerName] = useState(''); // Cambiado de tableNumber
  const [singerNameError, setSingerNameError] = useState(''); // Cambiado de tableNumberError
  const [results, setResults] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [placeInfo, setPlaceInfo] = useState({});
  const [loadingPlaceInfo, setLoadingPlaceInfo] = useState(true);

  useEffect(() => {
    const fetchPlaceInfo = async () => {
      try {
        const info = await getKaraokePlaceInfo(formId);
        setPlaceInfo(info);
      } catch (error) {
        console.error('Error al obtener información del lugar de karaoke:', error);
      } finally {
        setLoadingPlaceInfo(false);
      }
    };
    fetchPlaceInfo();
  }, [formId]);

  // Función para validar el nombre del cantante
  const validateSingerName = (name) => {
    if (!name.trim()) {
      return 'Por favor, ingresa tu nombre';
    }
    if (name.trim().length < 2) {
      return 'El nombre debe tener al menos 2 caracteres';
    }
    return '';
  };

  const isSingerNameValid = () => {
    return validateSingerName(singerName.trim()) === '';
  };

  const handleSingerNameChange = (e) => {
    const name = e.target.value;
    setSingerName(name);
    const error = validateSingerName(name);
    setSingerNameError(error);
  };

  const handleSearch = useCallback(async () => {
    const singerNameValidationError = validateSingerName(singerName.trim());
    if (singerNameValidationError) {
      setSingerNameError(singerNameValidationError);
      return;
    }
    if (!query.trim()) {
      setError('El campo de búsqueda no puede estar vacío');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const songs = await searchSongs(query);
      setResults(songs);
    } catch (error) {
      console.error('Error al buscar canciones:', error);
      setError('Hubo un error al buscar canciones. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  }, [query, singerName]);

  const handleSelect = useCallback((song) => {
    setSelectedSong(song);
    setResults([]);
    setQuery('');
  }, []);

  const handleSubmit = useCallback(async () => {
    const singerNameValidationError = validateSingerName(singerName.trim());
    if (!selectedSong || singerNameValidationError) {
      setSingerNameError(singerNameValidationError);
      setError('Por favor, completa todos los campos correctamente');
      return;
    }
    setLoading(true);
    try {
      const genreData = await getGenreFromDeezer(
        selectedSong.artist.id,
        selectedSong.album.id
      );
      
      await createKaraokeSongRequest(
        formId,
        selectedSong.title,
        selectedSong.artist.name,
        genreData.primary.join(', '),
        singerName.trim() // Cambiado de tableNumber
      );
      setOpen(true);
    } catch (error) {
      console.error('Error al agregar la canción:', error);
      setError('Hubo un error al agregar la canción. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  }, [formId, selectedSong, singerName]);

  const handleCancel = () => {
    setSelectedSong(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSong(null);
    setSingerName(''); // Limpiamos el nombre del cantante
    setSingerNameError('');
  };

  const resultsMemo = useMemo(() => results, [results]);

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <KaraokePlaceInfoSection placeInfo={placeInfo} loadingPlaceInfo={loadingPlaceInfo} />
        <Typography variant="h6" component="h2" sx={{ marginBottom: 2, textAlign: 'center', fontSize:'1.1rem' }}>
          ¿Qué canción quieres cantar?
        </Typography>

        {/* Campo para ingresar el nombre del cantante */}
        {!selectedSong && (
          <>
            <TextField
              fullWidth
              label="Tu nombre"
              value={singerName}
              onChange={handleSingerNameChange}
              sx={{
                marginBottom: '1rem',
                "& .MuiOutlinedInput-root": {
                  color: "#ffffff",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C2C2C2",
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C2C2C2",
                    },
                  },
                },
                "& .MuiInputLabel-outlined": {
                  color: "#C2C2C2",
                  "&.Mui-focused": {
                    color: "#868686",
                  },
                },
              }}
              error={!!singerNameError}
              helperText={singerNameError}
            />

            <TextField
              fullWidth
              label="Nombre de la canción"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{
                marginBottom: '2rem',
                "& .MuiOutlinedInput-root": {
                  color: "#ffffff",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C2C2C2",
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C2C2C2",
                    },
                  },
                },
                "& .MuiInputLabel-outlined": {
                  color: "#C2C2C2",
                  "&.Mui-focused": {
                    color: "#868686",
                  },
                },
              }}
              error={!!error}
              helperText={error}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSearch}
              sx={{ marginTop: -1, marginBottom: 2, backgroundColor: '#54A772' }}
              disabled={loading || !isSingerNameValid() || !singerName.trim()}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Buscar'}
            </Button>
          </>
        )}
        
        {loading && (
          <CircularProgress 
            sx={{ 
              display: 'block', 
              margin: '0 auto', 
              marginTop: 2 
            }} 
          />
        )}

        <List>
          {resultsMemo.map((song, index) => (
            <React.Fragment key={index}>
              <ListItem 
                onClick={() => handleSelect(song)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  },
                  transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
                  '&:active': {
                    transform: 'scale(0.98)',
                  },
                  padding: '10px 0',
                }}
              >
                <ListItemAvatar>
                  <Avatar 
                    src={song.album.cover} 
                    alt={song.title} 
                    sx={{ 
                      width: 56, 
                      height: 56, 
                      borderRadius: '8px', 
                      marginRight: 0.5 
                    }} 
                  />
                </ListItemAvatar>
                <ListItemText 
                  primary={song.title} 
                  secondary={song.artist.name} 
                  sx={{
                    marginLeft: 0.5,
                    fontWeight: 'bold',
                  }}
                />
              </ListItem>
              {index < resultsMemo.length - 1 && <Divider sx={{ marginY: 1 }} />}
            </React.Fragment>
          ))}
        </List>
        {selectedSong && (
          <KaraokeSelectedSongSection
            selectedSong={selectedSong}
            singerName={singerName}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            loading={loading}
            disabled={!isSingerNameValid() || !singerName.trim()}
          />
        )}
        <FooterLogo />
      </Paper>
      <KaraokeSongRequestModal
        open={open}
        handleClose={handleClose}
        placeInfo={placeInfo}
        singerName={singerName}
      />
    </Container>
  );
};

export default KaraokeSongRequestForm;
