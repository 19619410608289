import axios from 'axios';

const UNKNOWN_GENRE = "Género desconocido";

export const searchSongs = async (query) => {
  const response = await axios.get(`/api/deezer/search?q=${query}`);
  return response.data.data;
};

export const getGenreFromDeezer = async (artistId, albumId) => {
  try {
    // Si no hay IDs, retornar estructura por defecto
    if (!artistId && !albumId) {
      return createGenreResponse();
    }

    const [artistGenres, albumGenres] = await Promise.all([
      getArtistGenres(artistId),
      getAlbumGenres(albumId)
    ]);

    return {
      artist: artistGenres,
      album: albumGenres,
      primary: albumGenres.length ? albumGenres : 
              artistGenres.length ? artistGenres : 
              [UNKNOWN_GENRE]
    };

  } catch (error) {
    console.error("Error obteniendo género desde Deezer:", error);
    return createGenreResponse();
  }
};

const getArtistGenres = async (artistId) => {
  if (!artistId) return [];
  
  try {
    const { data } = await axios.get(`/api/deezer/artist/${artistId}`);
    return data?.genres?.data?.map(genre => genre.name) || [];
  } catch {
    return [];
  }
};

const getAlbumGenres = async (albumId) => {
  if (!albumId) return [];

  try {
    const { data } = await axios.get(`/api/deezer/album/${albumId}`);
    return data?.genres?.data?.map(genre => genre.name) || [];
  } catch {
    return [];
  }
};

const createGenreResponse = () => ({
  artist: [],
  album: [],
  primary: [UNKNOWN_GENRE]
});
