import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoExtendido from '../assets/Logo DJ Ponla extendido (sin fondo).png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { animateScroll as  scroller } from 'react-scroll';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToSection = (section) => {
    if (location.pathname !== '/') {
      navigate('/', { replace: false });
      setTimeout(() => {
        scroller.scrollTo(section, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }, 100);
    } else {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
    handleClose();
  };

  // Funciones nombradas para manejar los clics
  const handleTestimonialsClick = () => {
    scrollToSection('testimonials');
  };

  const handleDjsClick = () => {
    scrollToSection('djs');
  };

  const handleAboutClick = () => {
    handleClose();
    navigate('/nosotros');
  };
  const handlePricingClick = () => {
    handleClose();
    navigate('/comprar');
  };

  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img src={LogoExtendido} alt="Logo DJPonla" style={{ height: '70px' }} />
          </Link>
        </Box>
        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" onClick={handleMenu}>
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleTestimonialsClick}>Testimonios</MenuItem>
              <MenuItem onClick={handleDjsClick}>Nuestros DJs</MenuItem>
              <MenuItem onClick={handleAboutClick}>Sobre nosotros</MenuItem>
              <Button
              variant="contained" 
              onClick={handlePricingClick}
              sx={{ color:'#ffffff', backgroundColor:'#115beb',borderRadius: 4, ml:1, mr:1 }} 
              >¡Comprar ahora!</Button>
            </Menu>
          </>
        ) : (
          <>
            <Button color="inherit" onClick={handleTestimonialsClick}>
              Testimonios
            </Button>
            <Button color="inherit" onClick={handleDjsClick}>Nuestros DJs</Button>
            <Button color="inherit" onClick={handleAboutClick}>Sobre nosotros</Button>
            <Button 
              variant="contained"
              onClick={handlePricingClick}
              sx={{ color:'#ffffff', backgroundColor:'#115beb',borderRadius: 2, ml:2}} 
              >¡Comprar ahora!</Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
