import React from 'react';
import { Card, Box, Typography } from '@mui/material';

const StepCard = ({ step, index, activeStep }) => (
  <Card
    sx={{
      backgroundColor: "rgba(34, 34, 34, 0.9)",
      color: "white",
      p: 3,
      borderRadius: 2,
      height: "100%",
      position: "relative",
      overflow: "hidden",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "translateY(-5px)",
      },
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: "4px",
        background: activeStep === index ? "#b70a20" : "transparent",
      },
    }}
  >
    <Box component="img" src={step.image} alt={step.title} sx={{ maxWidth: '100%', maxHeight: 380, objectFit: 'contain', mb: 2, borderRadius: '14px' }} />
    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#b70a20' }}>
      {step.title}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
      {step.description}
    </Typography>
  </Card>
);

export default StepCard; 