import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import PaymentForm from './PaymentFormQR';
import { registerPaymentQR, registerInternationalPaymentQR } from "../../api/airtable";
import { uploadFileToCloudinary } from "../../api/cloudinary";

const PaymentStep = ({ onClose, selectedPrice, formData }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPeru, setIsPeru] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");

  useEffect(() => {
    const phoneNumber = formData.phone || "";
    setIsPeru(phoneNumber.startsWith("+51") || phoneNumber.startsWith("51"));
  }, [formData.phone]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopyMessage("¡Número copiado al portapapeles!");
        setTimeout(() => setCopyMessage(""), 3000);
      })
      .catch(() => {
        setCopyMessage("Error al copiar, inténtalo de nuevo.");
        setTimeout(() => setCopyMessage(""), 3000);
      });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      try {
        const uploadedFileUrl = await uploadFileToCloudinary(file);
        setFileUploaded(true);
        setFileName(file.name);
        formData.paymentFile = uploadedFileUrl;
        setErrorMessage("");
      } catch (error) {
        setErrorMessage("Hubo un error al subir el archivo.");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleConfirm = async () => {
    if (isPeru && !fileUploaded) {
      setErrorMessage("Debe subir un comprobante antes de confirmar el pago.");
      return;
    }

    try {
      setConfirming(true);
      setErrorMessage("");

      if (formData.photo && typeof formData.photo !== "string") {
        const uploadedPhotoUrl = await uploadFileToCloudinary(formData.photo);
        formData.photo = uploadedPhotoUrl;
      }

      const currentDate = new Date();
      const formattedPurchaseDate = currentDate.toLocaleDateString("es-PE");

      const paymentData = {
        djName: formData.djName,
        instagramLink: formData.instagramLink,
        phone: formData.phone,
        email: formData.email,
        plan: selectedPrice === "S/.35" ? "Mensual" : "Anual",
        price: selectedPrice,
        paymentDate: formattedPurchaseDate,
        photo: formData.photo,
      };

      if (isPeru) {
        paymentData.paymentFile = formData.paymentFile;
        await registerPaymentQR(paymentData);
      } else {
        await registerInternationalPaymentQR(paymentData);
      }

      setShowThankYouMessage(true);
      setPaymentCompleted(true);
      setConfirming(false);
    } catch (error) {
      setConfirming(false);
      console.error("Error al confirmar el pago:", error);
      setErrorMessage("Ocurrió un error al registrar el pago. Inténtelo nuevamente.");
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "600px",
        width: "100%",
        maxHeight: "70vh",
        overflowY: "auto",
        p: 3,
        backgroundColor: "#f5f5f5",
        borderRadius: 3,
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        animation: "fadeIn 0.8s ease-out",
      }}
    >
      {showThankYouMessage || paymentCompleted ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "#4caf50", mb: 2 }}>
            ¡Gracias por tu compra!
          </Typography>
          <Typography variant="body1" sx={{ color: "#555" }}>
            En minutos nos comunicaremos contigo con tus accesos.
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 3, fontWeight: "bold" }}
            onClick={onClose}
          >
            Cerrar
          </Button>
        </Box>
      ) : (
        <PaymentForm
          selectedPrice={selectedPrice}
          isPeru={isPeru}
          copyMessage={copyMessage}
          handleFileUpload={handleFileUpload}
          uploading={uploading}
          fileUploaded={fileUploaded}
          fileName={fileName}
          errorMessage={errorMessage}
          handleConfirm={handleConfirm}
          confirming={confirming}
          onClose={onClose}
          copyToClipboard={copyToClipboard}
        />
      )}
    </Box>
  );
};

export default PaymentStep;
