import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import PhoneInput from 'react-phone-input-2';

const BasicInfoForm = ({ formData, errors, handleChange, handleNext }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Información Básica
      </Typography>
      
      <TextField
        fullWidth
        label="Nombre de DJ"
        name="djName"
        value={formData.djName}
        onChange={handleChange}
        error={!!errors.djName}
        helperText={errors.djName || "Ejemplo: DJ Diego Pozo, Herrera DJ"}
        sx={{ mb: 2 }}
      />

      <TextField
        fullWidth
        label="Tu frase que más te identifica"
        name="tagline"
        value={formData.tagline}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />

      <PhoneInput
        country="pe"
        value={formData.phone}
        onChange={(phone) => handleChange({ target: { name: 'phone', value: phone }})}
        containerStyle={{ marginBottom: '16px' }}
      />

      <TextField
        fullWidth
        label="Correo Electrónico"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        error={!!errors.email}
        helperText={errors.email}
        sx={{ mb: 2 }}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={handleNext}
        sx={{
          mt: 2,
          backgroundColor: "#4caf50",
          "&:hover": { backgroundColor: "#45a049" }
        }}
      >
        Siguiente
      </Button>
    </Box>
  );
};

export default BasicInfoForm; 