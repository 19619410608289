import React from 'react';
import { Box, Typography, Divider, Button, Alert } from '@mui/material';
import PaymentMethods from './PaymentMethods';
import FileUpload from './FileUpload';
import CircularProgress from '@mui/material/CircularProgress';

const PaymentForm = ({
  selectedPrice,
  isPeru,
  copyMessage,
  handleFileUpload,
  uploading,
  fileUploaded,
  fileName,
  errorMessage,
  handleConfirm,
  confirming,
  onClose,
  copyToClipboard,
}) => (
  <>
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
      Realiza tu Pago
    </Typography>
    <Typography
      variant="h4"
      gutterBottom
      sx={{ color: '#ff5722', fontWeight: 'bold', textAlign: 'center', mt: 2 }}
    >
      {`${selectedPrice}`}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: '#555', mb: 2, textAlign: 'center' }}>
      Completa tu pago para activar tu cuenta y disfrutar de todos los beneficios.
    </Typography>
    <Divider sx={{ my: 2 }} />

    {isPeru ? (
      <>
        <PaymentMethods copyMessage={copyMessage} copyToClipboard={copyToClipboard} />
        <FileUpload
          handleFileUpload={handleFileUpload}
          uploading={uploading}
          fileUploaded={fileUploaded}
          fileName={fileName}
        />
      </>
    ) : (
      <Typography variant="body1" sx={{ color: '#555', textAlign: 'center', fontWeight: 'bold' }}>
        El número proporcionado no es de Perú. Nos contactaremos contigo al número proporcionado.
      </Typography>
    )}

    {errorMessage && (
      <Alert severity="error" sx={{ mb: 2 }}>
        {errorMessage}
      </Alert>
    )}

    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
      <Button variant="outlined" color="secondary" fullWidth onClick={onClose} sx={{ fontWeight: 'bold' }}>
        Cancelar
      </Button>
      <Button
        variant="contained"
        fullWidth
        onClick={handleConfirm}
        disabled={confirming}
        sx={{
          fontWeight: 'bold',
          backgroundColor: confirming ? '#e0e0e0' : '#4caf50',
          color: confirming ? '#212121' : '#ffffff',
          '&:hover': { backgroundColor: confirming ? '#bdbdbd' : '#388e3c' },
        }}
      >
        {confirming ? <CircularProgress size={24} sx={{ color: '#ffffff' }} /> : 'Confirmar y Finalizar'}
      </Button>
    </Box>
  </>
);

export default PaymentForm; 